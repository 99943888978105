import { Add, AddBox, EmojiEvents, Error, Event, EventAvailable, EventBusy, EventRepeat, Info, RunningWithErrors, SportsTennis } from '@mui/icons-material';
import { Accordion, AccordionDetails, AccordionSummary, Alert, AlertTitle, CircularProgress, Collapse, Divider, Grid, Link, List, ListItem, ListItemIcon, ListItemText, MenuItem, Stack, Tooltip, Typography } from '@mui/material';
import React, { useState } from 'react'
import ActionMenu from '../../../components/ActionMenu';
import { format } from 'date-fns';
import TertiaryButton from '../../../components/TertiaryButton';
import { de } from 'date-fns/locale';
import ReservationDialog from '../../../components/ReservationDialog';
import AddEditSeriesDialog from '../../../components/AddEditSeriesDialog';
import { useNavigate } from 'react-router-dom';
import ActionMenuItem from '../../../components/ActionMenuItem';
import { ADM_SERIES } from '../../../navigation/CONSTANTS';

function TeammatchPlannerTab({ matchdays, isLoading, getTeammatchesHomeGrouped, stats }) {

    const [planningInfo, setPlanningInfo] = useState(false);
    const [openReservationDialog, setOpenReservationDialog] = useState(false);
    const [targetDate, setTargetDate] = useState();
    const [startArgs, setStartArgs] = useState({ date: "", name: "" });
    const [openSeriesDialog, setOpenSeriesDialog] = useState(false);
    const navigate = useNavigate();
    const assignSeriesToMatchDay = (object) => {

        var tmpAssignments = [];
        var tmpNames = "Punktspiel: ";
        for (var i = 0; i < object.teammatches.length; i++) {

            tmpAssignments.push(object.teammatches[i]);
            if (i === object.teammatches.length - 1) {
                tmpNames += object.teammatches[i].teamName;
            } else {
                tmpNames += object.teammatches[i].teamName + ", ";
            }

        }
        setStartArgs({ ...startArgs, date: object.teammatches[0].date, name: tmpNames, assignments: tmpAssignments }); setOpenSeriesDialog(true)
    }

    return (
        <>
            <Grid container spacing={1} justifyContent={"center"} sx={{ py: 2 }}>
                <Grid item container xs={12} justifyContent={"flex-start"}>
                    <Grid item container xs={12} justifyContent="space-evenly">
                        <Grid item>
                            <Stack alignItems={"center"}>
                                <EmojiEvents color="primary" />
                                <Typography color="text.secondary" sx={{ fontWeight: "bold" }}>Heimspiele</Typography>
                                <Typography>{stats && stats[0].teammatchCount}</Typography>
                            </Stack>
                        </Grid>
                        <Grid item>
                            <Stack alignItems={"center"}>
                                <EventBusy color="warning" />
                                <Typography color="text.secondary" sx={{ fontWeight: "bold" }}>Fehlende Reservierung</Typography>
                                <Typography>{stats && stats[0].missingReservation}</Typography>
                            </Stack>
                        </Grid>
                        <Grid item>
                            <Stack alignItems={"center"}>
                                <RunningWithErrors color="primary" />
                                <Typography color="text.secondary" sx={{ fontWeight: "bold" }}>Verlegt</Typography>
                                <Typography>{stats && stats[0].dateChanged}</Typography>
                            </Stack>
                        </Grid>

                    </Grid>
                </Grid>
                <Grid item xs={12}>
                    <Divider></Divider>
                </Grid>
                <Grid item xs={12}>
                    {!planningInfo &&
                        <Stack direction="row" justifyContent={"center"} spacing={1} alignItems={"center"}>
                            <TertiaryButton startIcon={<Info color="primary" />} text={"Info - " + (stats && stats[0].missingReservation + stats[0].dateChanged) + " Handlungen notwendig"} onClick={() => setPlanningInfo(true)}></TertiaryButton>
                        </Stack>
                    }
                    <Collapse in={planningInfo}>
                        <Alert variant="outlined" severity="info" onClose={() => setPlanningInfo(false)}>
                            <AlertTitle>Info</AlertTitle>
                            <Typography variant="body2">Die Planungsansicht zeigt alle Heimspiele der aktuellen Saison an. Wenn ein Spieltag orange markiert ist, bedeutet dies, dass mindestens eine Platzbuchung fehlt. Bei grün markierten Spieltagen sind alle Punktspiele mit einer Platzbuchung versehen. Platzbuchungen können über das Menü entweder für einen ganzen Spieltag (alle Mannschaften werden zugewiesen) oder für einzelne Spiele vorgenommen werden</Typography>
                        </Alert>
                    </Collapse>
                </Grid>

                {isLoading ?
                    <Grid item>
                        <Stack alignItems={"center"} spacing={1}>
                            <CircularProgress></CircularProgress>
                            <Typography>Lade Heimpunktspiele</Typography>
                        </Stack>
                    </Grid>
                    :
                    matchdays && matchdays.length > 0 ?
                        matchdays.map((matchday, i) => (
                            <Grid item xs={12} md={10} key={i}>
                                <Accordion sx={{ borderLeft: 3, borderColor: matchday.count <= matchday.totalReservations ? "success.main" : "warning.main" }}>
                                    <AccordionSummary>
                                        <Stack direction="row" justifyContent={"space-between"} sx={{ width: "100%" }} alignItems={"center"}>
                                            <Stack direction="row" spacing={2}>
                                                {matchday.count <= matchday.totalReservations ?
                                                    <Tooltip title="Reservierungen vorhanden">
                                                        <EventAvailable color={"success"} />
                                                    </Tooltip>
                                                    :
                                                    <Tooltip title="Fehlende Reservierung oder Spiel/e verlegt">
                                                        <Error color={"warning"} />
                                                    </Tooltip>
                                                }
                                                <Typography color="text.secondary" variant="body1" fontWeight={500}>{matchday.date && format(new Date(matchday.date), "EEEE',' dd.MM", { locale: de })}</Typography>
                                            </Stack>
                                            <Stack direction="row" spacing={2} alignItems={"center"}>
                                                <Typography color="text.secondary" variant="body1" fontWeight={500}>{matchday.totalReservations + "/" + matchday.count + " Spiele"}</Typography>
                                                <ActionMenu>
                                                    {/* <MenuItem onClick={() => navigate(BOOKING + "/" + format(new Date(matchday.date), "yyyy-MM-dd"))}> */}
                                                    {/* <ActionMenuItem icon={<SportsTennis/>} itemText={"Platzbelegung angucken (" + format(new Date(matchday.date), "dd.MM") + ")"} onClick={() => { setTargetDate(new Date(matchday.date)); setOpenReservationDialog(true) }}/> */}
                                                    <MenuItem onClick={() => { setTargetDate(new Date(matchday.date)); setOpenReservationDialog(true) }}>
                                                        <ListItemIcon><SportsTennis></SportsTennis></ListItemIcon>
                                                        {"Platzbelegung angucken (" + format(new Date(matchday.date), "dd.MM") + ")"}
                                                    </MenuItem>
                                                    <MenuItem onClick={() => { navigate("/series") }}>
                                                        <ListItemIcon><EventRepeat /></ListItemIcon>
                                                        Zum Serienmanagent
                                                    </MenuItem>
                                                    <MenuItem onClick={() => { assignSeriesToMatchDay(matchday); }}>
                                                        <ListItemIcon><AddBox/></ListItemIcon>
                                                        Buchung erstellen (alle Mannschaften)
                                                    </MenuItem>
                                                </ActionMenu>
                                            </Stack>
                                        </Stack>

                                    </AccordionSummary>
                                    <AccordionDetails sx={{ borderTop: 1, borderColor: "divider", paddingX: 0 }}>
                                        <List dense>
                                            {matchday.teammatches && matchday.teammatches.map((teammatch, i) => (
                                                <ListItem secondaryAction={<ActionMenu>
                                                    <MenuItem onClick={() => { setStartArgs({ ...startArgs, date: teammatch.date, name: teammatch.teamName + " " + teammatch.opponent, assignments: [teammatch] }); setOpenSeriesDialog(true) }}>
                                                        <ListItemIcon><AddBox/></ListItemIcon>{"Platzbuchung erstellen (" + teammatch.teamName + ")"}
                                                    </MenuItem>
                                                    <MenuItem onClick={() => { navigate(ADM_SERIES + "?teammatch=" + teammatch.id + "&teammatch_name=" + encodeURI(teammatch.teamName + " " + teammatch.opponent)) }}>
                                                        <ListItemIcon><EventRepeat /></ListItemIcon>{"Verknüpfte Reservierungen anzeigen"}
                                                    </MenuItem>
                                                </ActionMenu>} key={i}>
                                                    <ListItemIcon>
                                                        {teammatch.hasReservation && teammatch.hasChanged != 1 ?
                                                            <Tooltip title="Reservierung vorhanden">
                                                                <EventAvailable color={"success"} />
                                                            </Tooltip>
                                                            : teammatch.hasReservation && teammatch.hasChanged ?
                                                                <Tooltip title="Reservierung vorhanden - Spiel verlegt ">
                                                                    <RunningWithErrors color="primary" />
                                                                </Tooltip>
                                                                :
                                                                <Tooltip title="Keine Reservierung vorhanden">
                                                                    <EventBusy color={"warning"} />
                                                                </Tooltip>
                                                        }
                                                    </ListItemIcon>
                                                    <ListItemText primary={<Stack>
                                                        <Typography color={"text.secondary"} fontWeight={"bold"}>{teammatch.teamName}</Typography>
                                                        <Typography color={"text.secondary"} variant="body2" >{teammatch.opponent}</Typography>
                                                    </Stack>} secondary={teammatch.date && format(new Date(teammatch.date), "dd.MM. HH:mm")}></ListItemText>
                                                </ListItem>
                                            ))
                                            }
                                        </List>
                                    </AccordionDetails>
                                </Accordion>
                            </Grid>
                        ))

                        :
                        <Grid item xs>
                            <Alert severity='info'>
                                <Typography>Keine anstehenden Heispiele</Typography>
                            </Alert>
                        </Grid>
                }
            </Grid>
            <ReservationDialog open={openReservationDialog} onClose={() => setOpenReservationDialog(false)} dateProp={targetDate} originName={"zur Punktspielplanung"}></ReservationDialog>
            <AddEditSeriesDialog open={openSeriesDialog} onClose={() => { setOpenSeriesDialog(false); }} getSeries={getTeammatchesHomeGrouped} isEdit={false} startDateArg={startArgs.date} startNameArg={startArgs.name} startAssignmentsArg={startArgs.assignments} />

        </>
    )
}

export default TeammatchPlannerTab