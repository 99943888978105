import { Table, TableBody, TableCell, TableContainer, TableHead, TableRow, Paper, Grid, Card, ToggleButtonGroup, ToggleButton, Alert, Divider, Container, MenuItem, ListItem, ListItemText, MenuList, ListItemIcon, Box, CircularProgress, } from '@mui/material';
import { Typography } from "@mui/material";
import React, { useState, useEffect } from 'react';
import DatePickFilter from '../../components/DatePickerFilter.jsx';
import { deleteReservation, deleteReservations } from '../../api/Reservations/reservations.js';
import { addDays, format, getDay, getWeek, sub } from 'date-fns';
import { myStyles } from '../../css/Sytles.jsx';
import AdminBookingDialog from './AdmBookingDialog.jsx';
import AdminBookingButton from './AdmBookingButton.jsx';
import MailDialog from '../../components/MailDialog.jsx';
import PrimaryIconButton from '../../components/PrimaryIconButton.jsx';
import { ChevronLeft, ChevronRight, Lock, QuestionMark, Web } from '@mui/icons-material';
import { de } from 'date-fns/locale';
import SecondaryButton from '../../components/SecondaryButton.jsx';
import InternalLink from '../../components/InternalLink.jsx';
import { ADM_COURTS } from '../../navigation/CONSTANTS.js';
import CourtsLockDialog from '../Admin/Courts/CourtsLockDialog.jsx';
import ActionMenu from '../../components/ActionMenu.jsx';
import { useNavigate } from 'react-router-dom';
import RuleDialog from '../booking/RuleDialog.jsx';
import { useSnackbar } from '../../components/SnackbarContext.jsx';


function AdminBookingTable(props) {

    //Modal state
    const [modalOpen, setModalOpen] = useState(false);
    const [newBookingData, setNewBookingData] = useState({ time: null, court: null, date: null })
    const [openRules, setOpenRules] = useState(false);

    const { showSnackbar } = useSnackbar();


    //delete Dialog
    const [deleteDialogOpen, setDeleteDialogOpen] = useState(false);
    const [selectedBookingId, setSelectedBookingId] = useState(null);
    const [selectedIDs, setSelectedIDs] = useState(new Array());
    const [isMassDelete, setIsMassDelete] = useState(false);
    const [openLockDialog, setOpenLockDialog] = useState(false);
    const [deleteDisabled, setDeleteDisabled] = useState(true);
    const navigate = useNavigate();

    const [classes, setClasses] = useState(myStyles());

    const [view, setView] = useState(getDay(new Date()) - 1);

    const handleView = (event, newView) => {
        setView(newView);

        if (newView == null) {
            return null;
        }
        else {
            props.setDayFilter(sub(new Date(), { days: getDay(new Date()) - newView - 1 }));
            // console.log(format(sub(new Date(),{days: getDay(new Date())-newView-1}),"yyyy-MM-dd"));
        }
    };


    //add or remove selected reservation
    const handleSelectedIDs = (id) => {
        var eq = (element) => element == id;
        var index = selectedIDs.findIndex(eq)

        if (index >= 0) {
            selectedIDs.splice(index, 1);
        }
        else {
            selectedIDs.push(id);
        }
    }

    const handleOpen = (event) => {
        setModalOpen(true);
    }
    const handleClose = () => setModalOpen(false);

    const handleOpenDeleteDialog = () => {
        setDeleteDialogOpen(true);
    }
    const handleMassDelete = () => {
        if (selectedIDs.length > 0) { setDeleteDialogOpen(true); setIsMassDelete(true); }
        else {
            showSnackbar("Bitte wähle mindestens eine Buchung aus!", "warning"); setDeleteDialogOpen(false); setIsMassDelete(false);
        }
    }
    const handleCloseDeleteDialog = () => setDeleteDialogOpen(false);

    const handleDelete = (deleteMessage) => {
        var reservationPromise = null;
        //distinguish between mass and single delete
        if (isMassDelete) {
            reservationPromise = deleteReservations(selectedIDs, deleteMessage);
        }
        else {
            reservationPromise = deleteReservation(selectedBookingId, deleteMessage);
        }
        reservationPromise
            .then(result => { props.populateTable(); showSnackbar("Reservierung/en gelöscht", "success"); setDeleteDialogOpen(false); setSelectedIDs(new Array()) })
            .catch(error => { showSnackbar("Beim Löschen ist etwas schief gelaufen!", "error"); setDeleteDialogOpen(false); });
    }

    useEffect(() => {
        setSelectedIDs(new Array());
        if (getWeek(new Date(), { weekStartsOn: 0 }) == getWeek(addDays(props.dayFilter, -1), { weekStartsOn: 0 })) { setView(getDay(addDays(props.dayFilter, -1))) }
        else {
            setView(null)
        }
        return () => {

        };
    }, [props.dayFilter]);

    return (
        <>
            <Grid container direction="row" alignItems="center" rowSpacing={2} columnSpacing={1} mb={1}>
                <Grid container item direction="row" justifyContent="space-between" alignItems={"center"}>
                    <Grid item align="left">
                        <PrimaryIconButton icon={<QuestionMark />} onClickAction={() => { setOpenRules(true); }} />
                    </Grid>
                    <Grid item justifyContent="center">
                        <Typography variant="h4" color="text.secondary">{format(props.dayFilter, "EEEE, dd.MM ", { locale: de })}</Typography>
                    </Grid>
                    <Grid item>
                        <Box sx={{ width: "40px", height: "40px" }}>
                            <DatePickFilter hideInput={true} dayFilter={props.dayFilter} inputFormat={"EEEE, dd.MMMM"} setDayFilter={props.setDayFilter} />
                        </Box>
                    </Grid>
                </Grid>
                <Grid item xs={12} sx={{ mt: 0 }}>
                    <Divider flexItem></Divider>
                    <Grid container direction="row" justifyContent={"center"}>
                        <Grid item >
                            <PrimaryIconButton icon={<ChevronLeft />} onClickAction={() => { props.setDayFilter(addDays(props.dayFilter, -1)) }} />
                            <ToggleButtonGroup variant="contained" color="primary" value={view} onChange={handleView} exclusive size="medium" >
                                <ToggleButton value={0} color="primary" sx={{ textTransform: "none", border: 0 }}>Mo</ToggleButton>
                                <ToggleButton value={1} sx={{ textTransform: "none", border: 0 }}>Di</ToggleButton>
                                <ToggleButton value={2} sx={{ textTransform: "none", border: 0 }}>Mi</ToggleButton>
                                <ToggleButton value={3} sx={{ textTransform: "none", border: 0 }}>Do</ToggleButton>
                                <ToggleButton value={4} sx={{ textTransform: "none", border: 0 }}>Fr</ToggleButton>
                                <ToggleButton value={5} sx={{ textTransform: "none", border: 0 }}>Sa</ToggleButton>
                                <ToggleButton value={6} sx={{ textTransform: "none", border: 0 }}>So</ToggleButton>
                            </ToggleButtonGroup>
                            <PrimaryIconButton icon={<ChevronRight />} onClickAction={() => { props.setDayFilter(addDays(props.dayFilter, 1)) }} />
                        </Grid>
                    </Grid>

                </Grid>
                <Grid item container justifyContent={"space-between"}>
                    <Grid item >
                        <SecondaryButton text="Auswahl löschen" onClick={() => { handleMassDelete() }}></SecondaryButton>
                    </Grid>
                    <Grid item >
                        <ActionMenu button buttonName={"Aktionen"}>
                            <MenuList dense>
                                <MenuItem onClick={() => setOpenLockDialog(true)}><ListItemIcon><Lock /></ListItemIcon>Plätze sperren</MenuItem>
                                <MenuItem onClick={() => navigate(ADM_COURTS)}><ListItemIcon><Web /></ListItemIcon>Platzverwaltung</MenuItem>
                            </MenuList>
                        </ActionMenu>
                    </Grid>
                </Grid>
                {!props.courts || props.courts.length < 1 ?
                    <Grid item xs={12}>
                        <Alert severity='info'><Typography variant="body1">Es sind keine Tennisplätze vorhanden</Typography><InternalLink linkname="Platzverwaltung" route={ADM_COURTS}></InternalLink></Alert>
                    </Grid>
                    :
                    <Grid item xs={12} align="center">
                        {!props.isLoading ?
                            <TableContainer sx={{ borderRadius: 5, left: 1, zIndex: 2, backgroundColor: "#eee" }}>
                                <Table size="small" sx={{ borderCollapse: 'separate', borderSpacing: '4px 4px' }}>

                                    <TableHead >
                                        <TableRow sx={{ borderRadius: 1 }}>
                                            <TableCell className={classes.tableHeader} sx={{ position: 'sticky', left: 0, zIndex: 1 }}><Typography sx={{ fontSize: { xs: '0.7rem', md: '1rem' }, fontWeight: 500 }}></Typography></TableCell>
                                            {props.courts && props.courts.map((court, i) => (
                                                <TableCell key={i} className={classes.tableHeader} align="center" ><Typography sx={{ fontSize: { xs: '0.7rem', md: '1rem' }, fontWeight: { xs: 500, md: 500 } }}>{court.name}</Typography></TableCell>
                                            ))}
                                        </TableRow>
                                    </TableHead>
                                    <TableBody>
                                        {props.rows.map((row, i) => (
                                            <TableRow hover={true} key={i} sx={{ borderRadius: 1, m: 10 }}>
                                                <TableCell sx={{ position: 'sticky', background: "#fff", left: 0, zIndex: 2, pl: 1, pr: 1 }}><Typography sx={{ fontSize: { xs: '0.7rem', md: '1rem' }, fontWeight: { xs: 500, md: 500 } }}>{row[0]}</Typography></TableCell>
                                                {props.courts && props.courts.map((court, i) => (
                                                    <TableCell key={i} className={classes.tableCell} align="center" sx={{ border: 0, p: 0, py: 0, pr: 1, backgroundColor: "#fff", borderRadius: "4px" }}>
                                                        <AdminBookingButton setSelectedIDs={handleSelectedIDs} setNewBookingData={setNewBookingData} zeit={row[0]} data={row[court.id]} handleOpen={handleOpen} day={props.dayFilter} courts={props.courts} court={court} />
                                                    </TableCell>
                                                ))}
                                            </TableRow>
                                        ))}
                                    </TableBody>
                                </Table>
                            </TableContainer>
                            :
                            <CircularProgress></CircularProgress>
                        }
                    </Grid>
                }
            </Grid>

            <AdminBookingDialog open={modalOpen} dayFilter={props.dayFilter} onClose={handleClose} newBookingData={newBookingData} populateTable={props.populateTable} settings={props.settings} courtType={props.courtType}></AdminBookingDialog>
            <MailDialog open={deleteDialogOpen} title={"Buchung stornieren.."} onClose={() => { handleCloseDeleteDialog() }} handleSubmit={handleDelete} submitName={"Löschen"} isHidden={false} isMailActivated={false}></MailDialog>
            <CourtsLockDialog open={openLockDialog} onClose={() => setOpenLockDialog(false)} getCourts={props.getCourts}></CourtsLockDialog>
            <RuleDialog open={openRules} onClose={() => setOpenRules(false)} courts={props.courts} settings={props.settings}></RuleDialog>


        </>
    );
}

export default AdminBookingTable;
