import { Alert, CardMedia, Container, Grid, Stack, Typography } from "@mui/material";
import React, { useState, useEffect, useRef } from "react";
import Footer from "../../components/Footer";
import Header from "../../components/Header/Header";

import { Feed } from "@mui/icons-material";
import TitleCard from "../../components/TitleCard";
import { getPosts_api } from "../../api/Posts/post";
import HeadlinePost from "./HeadlinePost";
import { format } from "date-fns";
import { de } from "date-fns/locale";
import { useNavigate, useParams } from "react-router-dom";


const BlogContainer = () => {

    const [posts, setPosts] = useState();
    const [loading, setLoading] = useState(false);
    const ref = useRef(null);
    const navigate = useNavigate();

    const openPost = (id) => {
        navigate("/blog/" + id);
    }
    const getPosts = () => {
        setLoading(true);
        var promise;
        promise = getPosts_api();
        promise.then((result) => { setPosts(result.data); setLoading(false); })
            .catch((error) => { setLoading(false); });

    }
    useEffect(() => {
        getPosts();
    }, []);
    return (
        <>
            <Header />
            <Grid container direction="row" rowSpacing={3} columnSpacing={2}>
                <Grid item xs={12}>
                    <TitleCard id="crd-title-blog" title={process.env.REACT_APP_CLUB_ABBREVATION + " Blog"} subheader={"News vom " + process.env.REACT_APP_CLUB_NAME} icon={<Feed sx={{ width: 40, height: 40 }}></Feed>}></TitleCard>
                </Grid>
            </Grid>
            <Container maxWidth={"lg"} sx={{ py: { xs: 0, md: 5 }, px: { xs: 0, md: 2 } }}>
                {/* loop over post array */}
                {posts && posts.length > 0 && posts[0] ?
                    <HeadlinePost post={posts[0]} />
                    :
                    <></>
                }
                <Grid container direction="row" spacing={1} alignItems={"center"} justifyContent={"center"} sx={{ p: 1 }} >
                    {posts && posts.length > 0 ? posts.map((post, i) =>
                        i == 0 ?
                            <></>
                            :
                            <Grid item xs={11} md={6} onClick={() => openPost(post.id)}>
                                <HeadlinePost post={post} imageHeight={{xs: "200px",md: "300px"}} headlineSize={{xs: "body1",md: "h5"}} />
                            </Grid>

                    )
                        :
                        <Grid item xs={12}>
                            <Alert severity="info"><Typography>Keine Beiträge vorhanden</Typography></Alert>
                        </Grid>
                    }
                </Grid>

            </Container>
            <Footer />
        </>
    );
};

export default BlogContainer;

