import React, { useEffect, useState } from 'react';
import { Collapse, Divider, Drawer, List, ListItemButton, ListItemIcon, ListItemText, Typography } from '@mui/material';
import { ADM_EVENTS, ADM_SERIES, ADM_TEAMMATCHES, ADM_TEAMS, ADM_TRAININGGROUPS, BOOKING, DASHBOARD, LOG, MYEVENTS, MYTEAM, MYTEAMMATCHES, MYTRAININGGROUPS, USERS, ADM_DASHBOARD, MYBOOKINGS, ADM_BOOKING, ADM_STATISTICS, USERSME, ADM_RESERVATIONS, ADM_COURTS, ADM_BLOG, BLOG, ADM_SURVEYS, MYSURVEYS, SETTINGS, SUPPORT, ADM_TEMPLATES, ADM_TEAMMATCHESPLANNER, ADM_COURTLOCKS } from '../../navigation/CONSTANTS';

import { useSelector } from 'react-redux';
import { useLocation, useNavigate } from 'react-router-dom';
import { AccountBalance, ArticleSharp, AssignmentTurnedIn, BarChart, Block, CalendarMonth, Dashboard, EmojiEvents, Event, EventAvailable, EventRepeat, ExpandLess, ExpandMore, Feed, FitnessCenter, Groups, Home, Insights, LiveHelp, Mail, People, Person, Poll, Settings, SportsBaseballSharp, SportsTennis, Web } from '@mui/icons-material';


function DrawerMenu(props) {

  const { open, setOpen } = props

  const [courtMgmt, setCourtMgnmt] = useState(false);
  const [analytics, setAanalytics] = useState(false);
  const [clubmngmt, setClubMngmt] = useState(false);

  const [listIconColor, setListIconColor] = useState("rgb(81 132 176)");
  const [listTextColor, setListTextColor] = useState("rgb(42 92 136)");

  const isAuthenticated = useSelector((state)=>state.isAuthenticated);
  const isAdmin = useSelector((state)=>state.isAdmin);

  const location = useLocation();
  const navigate = useNavigate();


  const clubmngmtRoutes = [ADM_EVENTS, ADM_TEAMMATCHES, ADM_TEAMMATCHES, ADM_TEAMMATCHESPLANNER, ADM_TRAININGGROUPS, ADM_TEAMS, USERS, SETTINGS];
  const analyticsRoutes = [LOG, ADM_STATISTICS];
  const courtMgmtRoutes = [ADM_BOOKING,ADM_SERIES,ADM_RESERVATIONS,ADM_COURTS];

  const handleClose = (path, external = false) => {
    setOpen(false);
    if (!external) {
      navigate(path);
    }
    else {
      window.open(path, '_blank').focus();
    }
  };

  useEffect(() => {
    if (clubmngmtRoutes.some(element => location.pathname.includes(element)) && location.pathname != USERSME) {
      setClubMngmt(true);
    }
    if (analyticsRoutes.some(element => location.pathname.includes(element))) {
      setAanalytics(true);
    }
    if (courtMgmtRoutes.some(element => location.pathname.includes(element))) {
      setCourtMgnmt(true);
    }
  }, [location]);

  return (
    <>
      <Drawer anchor={"right"} open={open} onClose={() => { setOpen(false); }} sx={{ backdropFilter: "blur(13px)",maxHeight: "calc(100% - 48px)", top: "48px" }}>
        <List>
          {process.env.REACT_APP_CLUB_URL && process.env.REACT_APP_CLUB_URL != "" ?
            < ListItemButton id="btn-mainmenu-cluburl" onClick={() => { handleClose(process.env.REACT_APP_CLUB_URL, true); }}>
              <ListItemIcon sx={{ color: listIconColor }}>
                <Home fontSize="small" />
              </ListItemIcon>
              <ListItemText sx={{ color: listTextColor }} primaryTypographyProps={{ fontWeight: 500 }}>{process.env.REACT_APP_CLUB_ABBREVATION + " Website"}</ListItemText>
            </ListItemButton>
            : <div></div>
          }
          {process.env.REACT_APP_CLUB_NAME == "Heidberger Tennis Club" ?
            <ListItemButton  id="btn-mainmenu-trainingsplaner" onClick={() => { handleClose("https://training.htc-braunschweig.de", true); }}>
              <ListItemIcon sx={{ color: listIconColor }}>
                <SportsBaseballSharp fontSize="small" />
              </ListItemIcon>
              <ListItemText sx={{ color: listTextColor }} primaryTypographyProps={{ fontWeight: 500 }}>Trainingsplaner</ListItemText>
            </ListItemButton>
            :
            <div></div>
          }
          <ListItemButton id="btn-mainmenu-blog" selected={location.pathname.includes(BLOG)} onClick={() => { handleClose(BLOG); }}>
            <ListItemIcon sx={{ color: listIconColor }}>
              <Feed fontSize="small" />
            </ListItemIcon>
            <ListItemText sx={{ color: listTextColor }} primaryTypographyProps={{ fontWeight: 500 }}>Blog</ListItemText>
          </ListItemButton>
          {isAuthenticated &&
            <>
              <Divider><Typography variant="body2">{"Mein " + process.env.REACT_APP_CLUB_ABBREVATION}</Typography></Divider>
              <ListItemButton id="btn-mainmenu-dashboard" selected={location.pathname === DASHBOARD} onClick={() => { handleClose(DASHBOARD); }}>
                <ListItemIcon sx={{ color: listIconColor }}>
                  <Dashboard fontSize="small" />
                </ListItemIcon>
                <ListItemText sx={{ color: listTextColor }} primaryTypographyProps={{ fontWeight: 500 }}>Dashboard</ListItemText>
              </ListItemButton>
              <ListItemButton id="btn-mainmenu-profile"selected={location.pathname === USERSME} onClick={() => { handleClose(USERSME); }}>
                <ListItemIcon sx={{ color: listIconColor }}>
                  <Person fontSize="small" />
                </ListItemIcon>
                <ListItemText sx={{ color: listTextColor }} primaryTypographyProps={{ fontWeight: 500 }}>Profil</ListItemText>
              </ListItemButton>
              <ListItemButton id="btn-mainmenu-booking" selected={location.pathname === BOOKING} onClick={() => { handleClose(BOOKING); }}>
                <ListItemIcon sx={{ color: listIconColor }}>
                  <SportsTennis fontSize="small" />
                </ListItemIcon>
                <ListItemText sx={{ color: listTextColor }} primaryTypographyProps={{ fontWeight: 500 }}> Buchen</ListItemText>
              </ListItemButton>
              <ListItemButton id="btn-mainmenu-mybookings" selected={location.pathname === MYBOOKINGS} onClick={() => { handleClose(MYBOOKINGS); }}>
                <ListItemIcon sx={{ color: listIconColor }}>
                  <EventAvailable fontSize="small" />
                </ListItemIcon>
                <ListItemText sx={{ color: listTextColor }} primaryTypographyProps={{ fontWeight: 500 }}> Meine Buchungen</ListItemText>
              </ListItemButton>
              <ListItemButton id="btn-mainmenu-myteam" selected={location.pathname === MYTEAM} onClick={() => { handleClose(MYTEAM); }}>
                <ListItemIcon sx={{ color: listIconColor }}>
                  <People fontSize="small" />
                </ListItemIcon>
                <ListItemText sx={{ color: listTextColor }} primaryTypographyProps={{ fontWeight: 500 }}> Mannschaft(en)</ListItemText>
              </ListItemButton>
              <ListItemButton id="btn-mainmenu-mytraining" selected={location.pathname === MYTRAININGGROUPS} onClick={() => { handleClose(MYTRAININGGROUPS); }}>
                <ListItemIcon sx={{ color: listIconColor }}>
                  <FitnessCenter fontSize="small" />
                </ListItemIcon>
                <ListItemText sx={{ color: listTextColor }} primaryTypographyProps={{ fontWeight: 500 }}> Training</ListItemText>
              </ListItemButton>
              <ListItemButton id="btn-mainmenu-mysurveys" selected={location.pathname === MYSURVEYS} onClick={() => { handleClose(MYSURVEYS); }}>
                <ListItemIcon sx={{ color: listIconColor }}>
                  <Poll fontSize="small" />
                </ListItemIcon>
                <ListItemText sx={{ color: listTextColor }} primaryTypographyProps={{ fontWeight: 500 }}> Meine Umfragen</ListItemText>
              </ListItemButton>
              <ListItemButton id="btn-mainmenu-myteammatches" selected={location.pathname === MYTEAMMATCHES} onClick={() => { handleClose(MYTEAMMATCHES); }}>
                <ListItemIcon sx={{ color: listIconColor }}>
                  <EmojiEvents fontSize="small" />
                </ListItemIcon>
                <ListItemText sx={{ color: listTextColor }} primaryTypographyProps={{ fontWeight: 500 }}> Punktspiele</ListItemText>
              </ListItemButton>
              <ListItemButton id="btn-mainmenu-myevents" selected={location.pathname === MYEVENTS} onClick={() => { handleClose(MYEVENTS) }}>
                <ListItemIcon sx={{ color: listIconColor }}>
                  <Event fontSize="small" />
                </ListItemIcon>
                <ListItemText sx={{ color: listTextColor }} primaryTypographyProps={{ fontWeight: 500 }}>Events</ListItemText>
              </ListItemButton>
              <ListItemButton id="btn-mainmenu-support" selected={location.pathname === SUPPORT} onClick={() => { handleClose(SUPPORT) }}>
                <ListItemIcon sx={{ color: listIconColor }}>
                  <LiveHelp fontSize="small" />
                </ListItemIcon>
                <ListItemText sx={{ color: listTextColor }} primaryTypographyProps={{ fontWeight: 500 }}>Support</ListItemText>
              </ListItemButton>
            </>
          }
          {isAdmin &&
            <>
              <Divider><Typography variant="body2">Admin</Typography></Divider>
              <ListItemButton selected={location.pathname === ADM_DASHBOARD} onClick={() => { handleClose(ADM_DASHBOARD) }}>
                <ListItemIcon sx={{ color: listIconColor }}>
                  <Dashboard fontSize="small" />
                </ListItemIcon>
                <ListItemText sx={{ color: listTextColor }} primaryTypographyProps={{ fontWeight: 500 }}>Admin Dashboard</ListItemText>
              </ListItemButton>

              <ListItemButton id="btn-mainmenu-clubmanagement" onClick={() => { setClubMngmt(!clubmngmt) }}>
                <ListItemIcon sx={{ color: listIconColor }}>
                  <AccountBalance fontSize="small" />
                </ListItemIcon>
                <ListItemText sx={{ color: listTextColor }} primaryTypographyProps={{ fontWeight: 500 }}> Vereinsverwaltung</ListItemText>
                {clubmngmt ? <ExpandLess sx={{ color: listTextColor }} /> : <ExpandMore sx={{ color: listTextColor }} />}
              </ListItemButton>
              <Collapse in={clubmngmt} timeout="auto" unmountOnExit>
                <List disablePadding>
                  <ListItemButton id="btn-mainmenu-settings" selected={location.pathname === SETTINGS} sx={{ pl: 4 }} onClick={() => { handleClose(SETTINGS) }}>
                    <ListItemIcon sx={{ color: listIconColor }}>
                      <Settings fontSize="small" />
                    </ListItemIcon>
                    <ListItemText sx={{ color: listTextColor }} primaryTypographyProps={{ fontWeight: 500 }}> Einstellungen</ListItemText>
                  </ListItemButton>
                  <ListItemButton selected={location.pathname.includes(USERS) && location.pathname != USERSME} sx={{ pl: 4 }} onClick={() => { handleClose(USERS) }}>
                    <ListItemIcon sx={{ color: listIconColor }}>
                      <Groups fontSize="small" />
                    </ListItemIcon>
                    <ListItemText sx={{ color: listTextColor }} primaryTypographyProps={{ fontWeight: 500 }}> Mitglieder</ListItemText>
                  </ListItemButton>
                  <ListItemButton selected={location.pathname === ADM_TEAMS} sx={{ pl: 4 }} onClick={() => { handleClose(ADM_TEAMS) }}>
                    <ListItemIcon sx={{ color: listIconColor }}>
                      <People fontSize="small" />
                    </ListItemIcon>
                    <ListItemText sx={{ color: listTextColor }} primaryTypographyProps={{ fontWeight: 500 }}> Mannschaften</ListItemText>
                  </ListItemButton>
                  <ListItemButton selected={location.pathname === ADM_TEAMMATCHES  && !location.search.includes("tab=planning")} sx={{ pl: 4 }} onClick={() => { handleClose(ADM_TEAMMATCHES+"?tab=matches") }}>
                    <ListItemIcon sx={{ color: listIconColor }}>
                      <EmojiEvents fontSize="small" />
                    </ListItemIcon>
                    <ListItemText sx={{ color: listTextColor }} primaryTypographyProps={{ fontWeight: 500 }}> Punktspiele</ListItemText>
                  </ListItemButton>
                  <ListItemButton selected={location.pathname === ADM_TEAMMATCHES && location.search.includes("tab=planning")} sx={{ pl: 4 }} onClick={() => { handleClose(ADM_TEAMMATCHESPLANNER) }}>
                    <ListItemIcon sx={{ color: listIconColor }}>
                      <AssignmentTurnedIn fontSize="small" />
                    </ListItemIcon>
                    <ListItemText sx={{ color: listTextColor }} primaryTypographyProps={{ fontWeight: 500 }}> Punktspielplaner</ListItemText>
                  </ListItemButton>
                  <ListItemButton id="btn-mainmenu-traininggroups" selected={location.pathname === ADM_TRAININGGROUPS} sx={{ pl: 4 }} onClick={() => { handleClose(ADM_TRAININGGROUPS) }}>
                    <ListItemIcon sx={{ color: listIconColor }}>
                      <FitnessCenter fontSize="small" />
                    </ListItemIcon>
                    <ListItemText  sx={{ color: listTextColor }} primaryTypographyProps={{ fontWeight: 500 }}>Training</ListItemText>
                  </ListItemButton>
                  <ListItemButton id="btn-mainmenu-events" selected={location.pathname === ADM_EVENTS} sx={{ pl: 4 }} onClick={() => { handleClose(ADM_EVENTS) }}>
                    <ListItemIcon sx={{ color: listIconColor }}>
                      <Event fontSize="small" />
                    </ListItemIcon>
                    <ListItemText sx={{ color: listTextColor }} primaryTypographyProps={{ fontWeight: 500 }}>Events</ListItemText>
                  </ListItemButton>
                  <ListItemButton selected={location.pathname === ADM_TEMPLATES} sx={{ pl: 4 }} onClick={() => { handleClose(ADM_TEMPLATES) }}>
                    <ListItemIcon sx={{ color: listIconColor }}>
                      <Mail fontSize="small" />
                    </ListItemIcon>
                    <ListItemText sx={{ color: listTextColor }} primaryTypographyProps={{ fontWeight: 500 }}>E-Mail Vorlagen</ListItemText>
                  </ListItemButton>
                </List>
              </Collapse>

              <ListItemButton id="btn-mainmenu-courtmanagement" onClick={() => { setCourtMgnmt(!courtMgmt) }}>
                <ListItemIcon sx={{ color: listIconColor }}>
                  <Web fontSize="small" />
                </ListItemIcon>
                <ListItemText sx={{ color: listTextColor }} primaryTypographyProps={{ fontWeight: 500 }}> Platzverwaltung</ListItemText>
                {courtMgmt ? <ExpandLess sx={{ color: listTextColor }} /> : <ExpandMore sx={{ color: listTextColor }} />}
              </ListItemButton>
              <Collapse in={courtMgmt} timeout="auto" unmountOnExit>
                <List disablePadding>
                  <ListItemButton id="btn-mainmenu-courts" selected={location.pathname === ADM_COURTS} sx={{ pl: 4 }} onClick={() => { handleClose(ADM_COURTS) }}>
                    <ListItemIcon sx={{ color: listIconColor }}>
                      <Web fontSize="small" />
                    </ListItemIcon>
                    <ListItemText sx={{ color: listTextColor }} primaryTypographyProps={{ fontWeight: 500 }}>Plätze</ListItemText>
                  </ListItemButton>
                  <ListItemButton selected={location.pathname === ADM_COURTLOCKS} sx={{ pl: 4 }} onClick={() => { handleClose(ADM_COURTLOCKS) }}>
                    <ListItemIcon sx={{ color: listIconColor }}>
                      <Block fontSize="small" />
                    </ListItemIcon>
                    <ListItemText sx={{ color: listTextColor }} primaryTypographyProps={{ fontWeight: 500 }}> Platzsperrungen</ListItemText>
                  </ListItemButton>
                  <ListItemButton selected={location.pathname === ADM_RESERVATIONS} sx={{ pl: 4 }} onClick={() => { handleClose(ADM_RESERVATIONS) }}>
                    <ListItemIcon sx={{ color: listIconColor }}>
                      <ArticleSharp fontSize="small" />
                    </ListItemIcon>
                    <ListItemText sx={{ color: listTextColor }} primaryTypographyProps={{ fontWeight: 500 }}> Reservierungen</ListItemText>
                  </ListItemButton>
                  <ListItemButton selected={location.pathname === ADM_BOOKING} sx={{ pl: 4 }} onClick={() => { handleClose(ADM_BOOKING) }}>
                    <ListItemIcon sx={{ color: listIconColor }}>
                      <SportsTennis fontSize="small" />
                    </ListItemIcon>
                    <ListItemText sx={{ color: listTextColor }} primaryTypographyProps={{ fontWeight: 500 }}>Buchen</ListItemText>
                  </ListItemButton>
                  <ListItemButton selected={location.pathname === ADM_SERIES} sx={{ pl: 4 }} onClick={() => { handleClose(ADM_SERIES) }}>
                    <ListItemIcon sx={{ color: listIconColor }}>
                      <EventRepeat fontSize="small" />
                    </ListItemIcon>
                    <ListItemText sx={{ color: listTextColor }} primaryTypographyProps={{ fontWeight: 500 }}>Serienbuchungen</ListItemText>
                  </ListItemButton>
                </List>
              </Collapse>

              <ListItemButton selected={location.pathname === ADM_SURVEYS} onClick={() => { handleClose(ADM_SURVEYS) }}>
                <ListItemIcon sx={{ color: listIconColor }}>
                  <Poll fontSize="small" />
                </ListItemIcon>
                <ListItemText sx={{ color: listTextColor }} primaryTypographyProps={{ fontWeight: 500 }}>Umfragen</ListItemText>
              </ListItemButton>
              <ListItemButton selected={location.pathname === ADM_BLOG} onClick={() => { handleClose(ADM_BLOG) }}>
                <ListItemIcon sx={{ color: listIconColor }}>
                  <Feed fontSize="small" />
                </ListItemIcon>
                <ListItemText sx={{ color: listTextColor }} primaryTypographyProps={{ fontWeight: 500 }}> Blog-Management</ListItemText>
              </ListItemButton>

              <ListItemButton onClick={() => { setAanalytics(!analytics) }}>
                <ListItemIcon sx={{ color: listIconColor }}>
                  <Insights fontSize="small" />
                </ListItemIcon>
                <ListItemText sx={{ color: listTextColor }} primaryTypographyProps={{ fontWeight: 500 }}> Analytics</ListItemText>
                {analytics ? <ExpandLess sx={{ color: listTextColor }} /> : <ExpandMore sx={{ color: listTextColor }} />}
              </ListItemButton>
              <Collapse in={analytics} timeout="auto" unmountOnExit>
                <List disablePadding>
                  <ListItemButton selected={location.pathname === LOG} sx={{ pl: 4 }} onClick={() => { handleClose(LOG) }}>
                    <ListItemIcon sx={{ color: listIconColor }}>
                      <ArticleSharp fontSize="small" />
                    </ListItemIcon>
                    <ListItemText sx={{ color: listTextColor }} primaryTypographyProps={{ fontWeight: 500 }}> Logs</ListItemText>
                  </ListItemButton>
                  <ListItemButton selected={location.pathname === ADM_STATISTICS} sx={{ pl: 4 }} onClick={() => { handleClose(ADM_STATISTICS) }}>
                    <ListItemIcon sx={{ color: listIconColor }}>
                      <BarChart fontSize="small" />
                    </ListItemIcon>
                    <ListItemText sx={{ color: listTextColor }} primaryTypographyProps={{ fontWeight: 500 }}>Statistiken</ListItemText>
                  </ListItemButton>
                </List>
              </Collapse>
            </>
          }
        </List>
      </Drawer >
    </>
  );
}



export default DrawerMenu;