import { Card, CardContent, Container, useMediaQuery, useTheme } from '@mui/material'
import React from 'react'

function MainContentContainer({ children, wrapInCard,maxWidth="xl",py=3,px=1, ...props }) {

  const theme = useTheme();
  const isSmallScreen = useMediaQuery(theme.breakpoints.down('md')); // check if screen is small

  const footerHeightOffset = "90px";
  const headerHightOffset = "176px";
  const calcString = "calc(100vh - "+ headerHightOffset +  " - " +footerHeightOffset+")";

  return (

    <Container maxWidth={maxWidth} sx={{py: py, px: px,minHeight: calcString,overflowX: 'hidden'}} {...props}>
      {wrapInCard ?
        <Card>
          <CardContent>
            {children}
          </CardContent></Card>
        :
         children 
      }
    </Container>
  )
}

export default MainContentContainer