import { Box, Button, Checkbox, Divider, Stack, Typography } from '@mui/material'
import React, { useEffect, useState } from 'react'
import { myStyles } from '../../css/Sytles';
import {format,} from 'date-fns';


function AdminBookingButton(props) {
  const { data, zeit, handleOpen, setNewBookingData, setSelectedIDs, day, court } = props;

  const [checkState, setCheckState] = useState(false);
  const [bookingDate, setBookingDate] = useState(false);

  const classes = myStyles();

  const [locked, setLocked] = useState();

  const handleClick = () => {
    setNewBookingData({ time: zeit, court: court.id, name: court.name });
    handleOpen();
  }

  useEffect(() => {
    var datetime = new Date(format(day, "yyyy-MM-dd") + "T" + zeit + ":00");
    setBookingDate(datetime);
  }, [day]);

  useEffect(() => {
    if (data.type == "lock") {
      setLocked(true);
    }
    else {
      setLocked(false);
    }
    setCheckState(false);
  }, [data]);

  
  if (locked) {
    return (<>
      <Box flexDirection="row" sx={{ backgroundColor: "#ccc", borderRadius: 1, width: "inherit", p: '4px', borderLeft: 4, borderColor: "error.main" }} justifyContent="center">
        <Stack direction="row" spacing={0.5} alignItems={"center"}>
          <Checkbox size="small" checked={checkState} onClick={() => { setSelectedIDs(data.id); setCheckState(!checkState) }} />
          <Divider flexItem orientation='vertical' ></Divider>
          <Stack flexGrow={1} sx={{ maxWidth: "300px" }}>
            <Typography align="center" sx={{ fontSize: { md: '0.9rem', xs: '0.7rem' }, fontWeight: { md: 500, xs: 500 } }} >{"Gesperrt"}</Typography>
            <Typography sx={{ fontSize: { md: '0.9rem', xs: '0.7rem' } }} >-</Typography>
          </Stack>
        </Stack>
      </Box>
    </>
    );
  }
  else {

    return (
      <>
        {!data || data.names === "" || data.names === undefined ?
          <Button className={classes.bookingButton} size="small" onClick={() => { handleClick() }} variant="outlined">Buchen</Button>
          :
          data.spieler1 != null && (data.spieler2 != null || data.guestname != null) ?
            // userbookings
            <Box flexDirection="row" sx={{ backgroundColor: "#ccc", borderRadius: 1, width: "inherit", p: '4px', pr: 1, borderLeft: 4, borderColor: "primary.main" }} justifyContent="center">
              <Stack direction="row" spacing={0.5} alignItems={"center"}>
                <Checkbox size="small" checked={checkState} onClick={() => { setSelectedIDs(data.id); setCheckState(!checkState) }} />
                <Divider flexItem orientation='vertical' ></Divider>
                <Stack flexGrow={1} sx={{ maxWidth: "300px" }}>
                  <Typography align="center" sx={{ fontSize: { md: '0.9rem', xs: '0.7rem' }, fontWeight: { md: 500, xs: 500 } }} >{data && data.spieler1_firstname.charAt(0) + ". " + data.spieler1_name}</Typography>
                  <Typography textAlign={"center"} sx={{ fontSize: { md: '0.9rem', xs: '0.7rem' }, fontWeight: { md: 500, xs: 500 } }} >{data.spieler2_name ? data.spieler2_firstname.charAt(0) + ". " + data.spieler2_name : data.guestname == "Ballmaschine" ? "Ballmaschine" : "Gast"}</Typography>
                </Stack>
              </Stack>
            </Box>
            : data.spieler1 != null && data.spieler2 == null && data.guestname == null ?
              // User FreeTextBooking
              <Box flexDirection="row" sx={{ backgroundColor: "#ccc", borderRadius: 1, width: "inherit", p: '4px', pr: 1, borderLeft: 4, borderColor: "warning.main" }} justifyContent="center">
                <Stack direction="row" spacing={0.5} alignItems={"center"} sx={{ maxWidth: "300px" }}>
                  <Checkbox size="small" checked={checkState} onClick={() => { setSelectedIDs(data.id); setCheckState(!checkState) }} />
                  <Divider flexItem orientation='vertical' ></Divider>
                  <Stack sx={{ flexGrow: 1 }}>
                    <Typography sx={{ fontSize: { md: '0.9rem', xs: '0.7rem' }, fontWeight: { md: 500, xs: 500 } }} >{data && data.spieler1_firstname.charAt(0) + ". " + data.spieler1_name}</Typography>
                    <Typography sx={{ fontSize: { md: '0.9rem', xs: '0.7rem' } }} noWrap>{data.names}</Typography>
                  </Stack>
                </Stack>
              </Box>
              :
              // Events & Series
              <Box flexDirection="row" sx={{ backgroundColor: "#ccc", borderRadius: 1, p: 1, borderLeft: 4, borderColor: "secondary.main", maxWidth: "inherit" }} justifyContent="center" >
                <Stack direction="row" spacing={0.5} alignItems={"center"} sx={{ maxWidth: "350px" }}>
                  <Checkbox size="small" checked={checkState} onClick={() => { setSelectedIDs(data.id); setCheckState(!checkState) }} sx={{ px: 0.5 }} />
                  <Divider flexItem orientation='vertical'></Divider>
                  <Stack flexGrow={1} sx={{ maxWidth: "300px" }}>
                    <Typography sx={{ fontSize: { md: '0.9rem', xs: '0.7rem' }, fontWeight: { md: 500, xs: 500 } }} noWrap>{data.names}</Typography>
                    <Typography sx={{ fontSize: { md: '0.9rem', xs: '0.7rem' } }} noWrap color="text.secondary">Serie</Typography>
                  </Stack>
                </Stack>
              </Box>
        }
      </>
    )
  }
}

export default AdminBookingButton