import React, { useState } from 'react';
import Typography from '@mui/material/Typography';
import { deleteReservation, deleteReservationReference_api, updateReservation } from '../api/Reservations/reservations.js';
import { format, getHours, isFuture, setHours } from 'date-fns';
import { Grid, Card, CardContent, Divider, Stack, Avatar, MenuItem, ListItemIcon } from '@mui/material';

import { Box } from '@mui/system';
import { Check, Delete, Group, LocationOnOutlined } from '@mui/icons-material';

import { de } from 'date-fns/locale';
import ActionMenu from './ActionMenu.jsx';
import { useSnackbar } from './SnackbarContext.jsx';


function BookingActionMenu(props) {
    return (
        <ActionMenu id={props.id}>
            {props.bookingData.confirmed === 0
                //booking needs to be in future
                && isFuture(new Date(props.bookingData.day + "T" + props.bookingData.time))
                //booking needs ta startwithin 2 hours
                && !isFuture(setHours(new Date(props.bookingData.day + "T" + props.bookingData.time), getHours(new Date(props.bookingData.day + "T" + props.bookingData.time)) - 2))
                ?
                <MenuItem onClick={() => { props.confirmAction(); }}>
                    <ListItemIcon>
                        <Check fontSize="small" />
                    </ListItemIcon>
                    Bestätigen
                </MenuItem>
                : <div></div>
            }
            {/* {isFuture(new Date(props.bookingData.day + "T" + props.bookingData.time)) || props.adminMode ? */}
            {true==true || props.adminMode ?
                <MenuItem id="btn-bookingcard-menu-delete" onClick={() => { props.deleteAction(); }}>
                    <ListItemIcon>
                        <Delete fontSize="small" />
                    </ListItemIcon>
                    Löschen
                </MenuItem>
                :
                <MenuItem id="btn-bookingcard-menu-delete" onClick={() => { props.deleteAction(); }} disabled>
                    <ListItemIcon>
                        <Delete fontSize="small" />
                    </ListItemIcon>
                    Löschen
                </MenuItem>
            }
        </ActionMenu>
    )
}


function BookingCard(props) {

    const { booking, adminMode, getBookings, clubSettings } = props;


    const {showSnackbar} = useSnackbar();

    const deleteHandler = () => {
        if (adminMode) {
            const reservationPromise = deleteReservation(booking.id, "");
            reservationPromise.then((result) => { getBookings(); showSnackbar("Buchung storniert!","success"); })
                .catch((error) => {showSnackbar("Error: ".error.response.data.error,"error");});
        }
        else {
            const reservationPromise = deleteReservationReference_api(booking.reference_id);
            reservationPromise.then((result) => { getBookings(); showSnackbar("Buchung storniert!","success"); })
                .catch((error) => {showSnackbar(error.response.data.error,"error");  });
        }

    }

    const confirmHandler = (bookingId) => {
        const reservationPromise = updateReservation(bookingId);
        reservationPromise.then((result) => { getBookings(); showSnackbar("Buchung bestätigt!","success");  })
            .catch((error) => { showSnackbar("Error: ".error.data.message,"error"); });
    }

    return (
        <React.Fragment>
            <Card sx={{ mb: 1, borderLeft: 6, borderColor: "primary.main" }}>
                <CardContent sx={{ p: 1, "&:last-child": { paddingBottom: 1 } }}>
                    <Grid container justifyContent={"flex-start"} alignItems={"center"}>
                        <Grid item sx={{ width: "80px" }}>
                            <Box sx={{ display: 'flex', flexDirection: 'column', p: 1, alignItems: "center" }}>
                                <Typography sx={{ typography: { xs: 'body1' } }} color="text.secondary">{format(new Date(booking.day + "T" + booking.time), "eeee", { locale: de })}</Typography>
                                <Typography sx={{ typography: { xs: 'h5', md: 'h4' } }}>{format(new Date(booking.day + "T" + booking.time), "d")}</Typography>
                                <Typography sx={{ typography: { xs: 'body1', md: 'body1' } }} color="text.secondary">{format(new Date(booking.day + "T" + booking.time), "MMM yy", { locale: de })}</Typography>
                            </Box>
                        </Grid>
                        <Divider orientation='vertical' flexItem sx={{ pl: 1 }}></Divider>
                        <Grid item xs sx={{ pl: 3 }}>
                            <Stack spacing={1}>
                                <Stack direction="row" spacing={2} alignItems="center">
                                    <Avatar sx={{ backgroundColor: "#E3F2FD" }}><Group color="primary"></Group></Avatar>
                                    <Stack>
                                        {booking.spieler2 || booking.spieler1 ?
                                            <Typography align="left" sx={{ typography: { xs: 'body2', md: 'body1' } }}>{booking.spieler1_firstname + " " + booking.spieler1_name}</Typography>
                                            :
                                            <>
                                                {booking.seriesID ? <Typography align="left" sx={{ typography: { xs: 'body2', md: 'body1' } }}>Serie</Typography> : <Typography align="left" sx={{ typography: { xs: 'body2', md: 'h6' } }}>Event</Typography>}
                                                <Typography align="left" sx={{ typography: { xs: 'body2', md: 'body1' } }}>{booking.name}</Typography>
                                            </>
                                        }
                                        {booking.spieler2 ?
                                            <Typography align="left" sx={{ typography: { xs: 'body2', md: 'body1' } }}>{booking.spieler2_firstname + " " + booking.spieler2_name || "na"}</Typography>
                                            : booking.guestname ?
                                                <Typography align="left" sx={{ typography: { xs: 'body2', md: 'body1' } }}>{booking.guestname}</Typography>
                                                : <Typography align="left" sx={{ typography: { xs: 'body2', md: 'body1' } }}>{booking.name}</Typography>
                                        }
                                    </Stack>
                                </Stack>
                                <Stack direction="row" spacing={2} alignItems="center">
                                    <Avatar sx={{ backgroundColor: "#E3F2FD" }}><LocationOnOutlined color="primary"></LocationOnOutlined></Avatar>
                                    <Stack>
                                        <Stack direction="row" spacing={1}>
                                            <Typography sx={{ typography: { xs: 'body2', md: 'body1' } }} color="text.secondary">{format(new Date(booking.day + "T" + booking.time), "HH:mm") + " Uhr "}</Typography>
                                            {booking.endtime &&
                                                <>
                                                    <Typography sx={{ typography: { xs: 'body2', md: 'body1' } }} color="text.secondary">{"-"}</Typography>
                                                    <Typography sx={{ typography: { xs: 'body2', md: 'body1' } }} color="text.secondary">{format(new Date(booking.endtime), "HH:mm") + " Uhr"}</Typography>
                                                </>
                                            }
                                        </Stack>
                                        <Stack direction="row" spacing={1}>
                                            <Typography sx={{ typography: { xs: 'body2', md: 'body1' } }} color="text.secondary">{booking.court_name}</Typography>
                                            {booking.reference_total_price != 0 && booking.reference_total_price ?
                                                <>
                                                    <Typography sx={{ typography: { xs: 'body2', md: 'body1' } }} color="text.secondary">{"|"}</Typography>
                                                    <Typography sx={{ typography: { xs: 'body2', md: 'body1' } }} color="text.secondary">{booking.reference_total_price ? booking.reference_total_price + "€" : ""}</Typography>


                                                </>

                                                : booking.price != 0 && booking.price &&
                                                <>
                                                    <Typography sx={{ typography: { xs: 'body2', md: 'body1' } }} color="text.secondary">{"|"}</Typography>
                                                    <Typography sx={{ typography: { xs: 'body2', md: 'body1' } }} color="text.secondary">{booking.price ? booking.price + "€" : ""}</Typography>
                                                </>
                                            }
                                            {clubSettings && clubSettings.booking_confirmation ?
                                                <Typography  sx={{ typography: { xs: 'body2', md: 'body1' } }} color="text.secondary">{booking.confirmed ? " | bestätigt" : " | austehend"}</Typography>
                                                :
                                                <></>
                                            }
                                        </Stack>
                                    </Stack>
                                </Stack>
                            </Stack>
                        </Grid>
                        <Grid item>
                            <BookingActionMenu id="bookingcard-menu" deleteAction={() => { deleteHandler() }} confirmAction={() => { confirmHandler(booking.id) }} bookingData={booking} adminMode={adminMode}></BookingActionMenu>
                        </Grid>

                    </Grid>
                </CardContent>
            </Card>
        </React.Fragment >
    );
}

export default BookingCard;
