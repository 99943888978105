import React, { useEffect, useState } from 'react'
import Footer from '../../components/Footer'

import { Alert, Grid, Typography } from '@mui/material'
import TournamentGroup from '../../components/TournamentGroup'
import { getTournamentGroups_api } from '../../api/Events/events'

function GroupsContainer({ tournamentId, ...props }) {
    const [groups, setGroups] = useState();

    const getGroups = () => {
        var promise = getTournamentGroups_api(tournamentId);
        promise.then((result) => setGroups(result.data))
            .catch();
    }

    useEffect(() => {
        getGroups();
    }, []);

    return (
        <>

            <Grid item container spacing={2}>
                {groups?.length > 0 ? groups?.map((group, i) => (
                    <Grid item xs={12} key={i}>
                        <TournamentGroup groupName={"Gruppe " + (i + 1)} players={group.players} matches={group.matches} getGroups={getGroups}></TournamentGroup>
                    </Grid>
                ))
                    :
                    <Grid item xs={12}>
                        <Alert severity="info" variant="filled"><Typography>Es gibt aktuell keine Gruppen</Typography></Alert>
                    </Grid>
                }
            </Grid>
            <Footer></Footer>
        </>
    )
}

export default GroupsContainer