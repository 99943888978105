import React, { useState } from 'react';
import TextField from '@mui/material/TextField';
import FormControlLabel from '@mui/material/FormControlLabel';
import Checkbox from '@mui/material/Checkbox';
import { Link } from 'react-router-dom';
import { Avatar, Button, Card, CardContent, CssBaseline, Divider, Grid, Box, Alert, Stack } from '@mui/material';
import { Link as LinkMui } from '@mui/material';
import Typography from '@mui/material/Typography';
import Container from '@mui/material/Container';
import { LOGIN, ROOT, TERMS } from '../../navigation/CONSTANTS';
import singupUser from '../../api/Auth/registration';

import Footer from '../../components/Footer';
import { PersonAdd } from '@mui/icons-material';
import { useSnackbar } from '../../components/SnackbarContext';


export default function SignUp() {

  const [signupStatus, setSignupStaus] = useState(false);
  const [missingTerms, setMissingTerms] = useState(false);
  const {showSnackbar} = useSnackbar();

  const handleSubmit = (event) => {
    event.preventDefault();

    const data = new FormData(event.currentTarget);
    if (data.get('password') !== data.get('passwordRepeat')) {
      showSnackbar("Die Passwörter müssen übereinstimmen!", "warning");
      return 0;
    }
    // eslint-disable-next-line no-console
    if (data.get('terms')) {
      const signupPromise = singupUser(data);
      signupPromise.then(result => { setMissingTerms(false); setSignupStaus(true); })
        .catch((error) => { showSnackbar("Fehler: "+error.response.data.error, "error"); });
    }
    else {
      setMissingTerms(true)
    }
  }

  return (
    <>
      <Container maxWidth="xs" sx={{
        display: 'flex',
        flexDirection: 'row',
        mb: 4
      }}>
        <Card elevation={24} sx={{ mt: 10 }}>
          <CardContent>
            <CssBaseline />
            <Box
              sx={{

                display: 'flex',
                flexDirection: 'column',
                alignItems: 'center',
              }}
            >
             <Grid container direction="row" justifyContent={"center"} spacing={1} sx={{mt: 1,mb:1}}>
                        <Grid item>
                        <Avatar sx={{ color: '#1E88E5', bgcolor: "#E3F2FD", width: "50px", height: "50px" }}><PersonAdd sx={{width: "30px", height: "30px" }}></PersonAdd></Avatar>
                        </Grid>
                        
                        <Grid item xs={12}>
                           <Typography textAlign={"center"} variant="h5" color="text.secondary" sx={{}}>{process.env.REACT_APP_CLUB_NAME}</Typography>
                        </Grid>
                        <Grid item xs={12}>
                           <Divider > <Typography textAlign={"center"} variant="body1" color="text.secondary" >Registrierung</Typography></Divider>
                        </Grid>
                     </Grid>
              {/* {!signupStatus &&
          
                <Grid item xs={12}>
                  <Alert severity="info"><Typography variant="body2">{"Die Nutzung des Portals ist nur für aktive Mitglieder des " + process.env.REACT_APP_CLUB_ABBREVATION + " möglich."}</Typography><LinkMui rel="noreferrer" target="_blank" href={process.env.REACT_APP_CLUB_URL} variant="body1"><Typography>{process.env.REACT_APP_CLUB_ABBREVATION + " Homepage"}</Typography></LinkMui>
                  </Alert>
                  <Divider sx={{ mt: 2, mb: 2}}></Divider>
                  </Grid>
              } */}

              {signupStatus ?
                <>
                  <Stack direction="column" spacing={2} alignItems={"center"}>
                    <Alert severity='success' variant="standard"><Typography>Registrierung erfolgreich! Deine Registrierung wird jetzt bearbeitet. Sobald Du für das System freigegeben bist, wirst Du benachrichtigt!</Typography></Alert>
                    <Link to={ROOT}>{process.env.REACT_APP_CLUB_NAME + " - Startseite"}</Link>
                  </Stack>
                </>
                :
                <Box component="form" noValidate onSubmit={handleSubmit} >
                  <Grid container spacing={2} >
                    <Grid item xs={12} sm={6}>
                      <TextField
                        autoComplete="given-name"
                        name="firstName"
                        required
                        fullWidth
                        id="firstName"
                        label="Vorname"
                        autoFocus
                      />
                    </Grid>
                    <Grid item xs={12} sm={6}>
                      <TextField
                        required
                        fullWidth
                        id="lastName"
                        label="Last Name"
                        name="lastName"
                        autoComplete="family-name"
                      />
                    </Grid>
                    <Grid item xs={12}>
                      <TextField
                        required
                        fullWidth
                        id="email"
                        label="Email"
                        name="email"
                        autoComplete="email"
                      />
                    </Grid>
                    <Grid item xs={12}>
                      <TextField
                        required
                        fullWidth
                        name="password"
                        label="Passwort"
                        type="password"
                        id="password"
                        autoComplete="new-password"
                      />
                    </Grid>
                    <Grid item xs={12}>
                      <TextField
                        required
                        fullWidth
                        name="passwordRepeat"
                        label="Passwort"
                        type="password"
                        id="password"
                        autoComplete="new-password"
                      />
                    </Grid>
                    <Grid item xs={12}>
                      <TextField
                        required
                        fullWidth
                        name="captcha"
                        label={process.env.REACT_APP_CAPTCHA_LABEL || "Initialien des "+process.env.REACT_APP_CLUB_ABBREVATION}
                        type="password"
                        id="captcha"
                      />
                    </Grid>
                    <Grid item xs={12}>
                      <FormControlLabel
                        control={<Checkbox value="consent" color="primary" />}
                        label={<Link to={TERMS}>Ich akzeptiere die Nutzungsbedingungen & Datenschutzbestimmungen</Link>}
                        name="terms"
                      />
                      {missingTerms &&
                        <Alert severity='warning'><Typography>Bitte akzeptiere die Nutzungsbedingungen</Typography></Alert>
                      }
                    </Grid>
                  </Grid>
                  <Button
                    type="submit"
                    fullWidth
                    variant="contained"
                    color="primary"
                    sx={{ mt: 3, mb: 2, textTransform: "none" }}
                  >
                    Registrieren
                  </Button>
                  <Grid container justifyContent="flex-end">
                    <Grid item>
                      <Link to={LOGIN}>
                        Sie haben bereits einen Account? Login
                      </Link>
                    </Grid>
                  </Grid>
                </Box>
              }
            </Box>
          </CardContent>
        </Card>
      </Container>
      <Footer></Footer>
    </>
  );
}