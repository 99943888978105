import axios from "axios";
import getSessionData from "../../utils/getSession";

export const getReservations_api = async (userFilter) => {
  const sessionData = getSessionData();
  //fetch reservation filtered by day data
  var user = "";
  if(userFilter)
  { 
    user="user="+userFilter;
    return await axios.get(
      process.env.REACT_APP_API_URL + "/reservation?"+user,
      { headers: sessionData }
    );
  }
  else
  {
    return await axios.get(
      process.env.REACT_APP_API_URL + "/reservation",
      { headers: sessionData }
    );
  }
 
};

export const getReservationsByDay_api = async (dayFilter,userFilter=null,courtType=null) => {
  const sessionData = getSessionData();
  //fetch reservation filtered by day data
  var user="";
  if(userFilter)
  { 
    user="&user="+userFilter;
    return await axios.get(
      process.env.REACT_APP_API_URL + "/reservation?filter=" + dayFilter+user+"&type="+courtType,
      { headers: sessionData }
    );
  }
  else
  {
    return await axios.get(
      process.env.REACT_APP_API_URL + "/reservation?filter=" + dayFilter+"&type="+courtType,
      { headers: sessionData }
    );
  }
  
};

export const getGuestReservations_api = async (userFilter,yearFilter) => {
  const sessionData = getSessionData();
  //fetch reservation filtered by day data
  
    var user="&user="+userFilter;
    var year= "&year="+yearFilter;

    return await axios.get(
      process.env.REACT_APP_API_URL + "/reservation?type=paid"+user+year,
      { headers: sessionData }
    );

};
export const exportGuestReservations_api = async (userFilter,yearFilter) => {
  const sessionData = getSessionData();
  //fetch reservation filtered by day data
  
    var user="&user="+userFilter;
    var year= "&year="+yearFilter;

    return await axios.get(
      process.env.REACT_APP_API_URL + "/reservation?type=paid"+user+year+"&format=csv",
      { headers: sessionData }
    );

};

export const getMyReservations = async (dayFilter, operator = "gt") => {
  const sessionData = getSessionData();
  //fetch reservation for current user filtered by day data
  return await axios.get(
    process.env.REACT_APP_API_URL + "/mybookings?filter=" + dayFilter+"&operator="+operator,
    { headers: sessionData }
  );
};

export const getMyGuestReservations_api = async () => {
  const sessionData = getSessionData();
  //fetch reservation for current user filtered by day data
  return await axios.get(
    process.env.REACT_APP_API_URL + "/mybookings/guest",
    { headers: sessionData }
  );
};

export const getMyPaidReservations_api = async () => {
  const sessionData = getSessionData();
  //fetch reservation for current user filtered by day data
  return await axios.get(
    process.env.REACT_APP_API_URL + "/mybookings/paid",
    { headers: sessionData }
  );
};

export const getMyNextReservation_api = async () => {
  const sessionData = getSessionData();
  //fetch reservation for current user filtered by day data
  return await axios.get(
    process.env.REACT_APP_API_URL + "/mybookings/next",
    { headers: sessionData }
  );
};

export const setReservation = async (data) => {
  const sessionData = getSessionData();

  const reservationData = {
    player1: data.player,
    player2: data.mate_id,
    court: data.court,
    time: data.time,
    day: data.day,
    name: data.name,
    guestname: data.guestname,
    endtime: data.endtime,
    starttime: data.starttime,
    isFreetext: data.isFreetext
  };

  //create new registration
  return await axios.post(
    process.env.REACT_APP_API_URL + "/reservation",
    reservationData,
    { headers: sessionData }
  );
};
//single delete
export const deleteReservation = async (id,message) => {
  const sessionData = getSessionData();

  //delete reservation
  return await axios.delete(
    process.env.REACT_APP_API_URL + "/reservation/"+id,{ headers: sessionData });
};

//delete reservation refernce (multiple connected bookings)
export const deleteReservationReference_api = async (referenceId) => {
  const sessionData = getSessionData();

  //delete reservation
  return await axios.delete(
    process.env.REACT_APP_API_URL + "/reservation/reference/"+referenceId,{ headers: sessionData });
};

//Mass delete (Admin)
export const deleteReservations = async (id,message) => {
  const sessionData = getSessionData();

  const data = {
    message: message,
    reservations:  Object.assign({}, id)
  };
  //delete reservation
  return await axios.post(
    process.env.REACT_APP_API_URL + "/reservation/delete",data,{ headers: sessionData });
};

export const deleteMyReservation_api = async (id) => {
  const sessionData = getSessionData();
  
  //delete reservation
  return await axios.delete(
    process.env.REACT_APP_API_URL + "/reservation/"+id,{ headers: sessionData });
};

export const updateReservation = async (bookingID) => {
  const sessionData = getSessionData();

  const reservationData = {
    id: bookingID,
    confirmed: 1,
  };

  //create new registration
  return await axios.patch(
    process.env.REACT_APP_API_URL + "/mybookings", reservationData,{ headers: sessionData }   
  );
};

export const confirmReservationByCode_api = async (code) => {
  const sessionData = getSessionData();
  //create new registration
  return await axios.get(
    process.env.REACT_APP_API_URL + "/reservation/courtconfirmation/?code="+code, { headers: sessionData }   
  );
};








