import { Accordion, AccordionDetails, AccordionSummary, Alert, AlertTitle,Chip, CircularProgress, Collapse, Dialog, DialogActions, DialogContent, DialogTitle, Divider, FormControl, Grid, Hidden, IconButton, InputLabel, List,MenuItem, Select, Stack, Step, StepContent, StepLabel, Stepper, Switch, Tabs, TextField, Typography } from '@mui/material'
import React, { useState, useEffect } from 'react'
import { setSeries_api, updateSeries_api } from '../api/Series/series';
import DateTimeField from './DateTimeField';
import PrimaryButton from './PrimaryButton';
import SecondaryButton from './SecondaryButton';
import WeekDayCheckboxes from './WeekDayCheckboxes';
import CourtSelect from './CourtSelect';
import { addHours, addMinutes, addMonths, compareDesc, format, getDayOfYear, getHours, getMinutes, isValid, setHours, setMinutes, setSeconds } from 'date-fns';
import { differenceInCalendarDays } from 'date-fns/esm/fp';
import { AddBox,Close, Delete, Event, EventRepeat, ExpandMore, Info, InfoSharp, Launch, LocationOn} from '@mui/icons-material';
import { de } from 'date-fns/locale';
import { getSettingsIndoor_api, getSettingsOutdoor_api } from '../api/Settings/settings';
import TertiaryButton from './TertiaryButton';
import { getTeammatches_api } from '../api/Teammatches/Teammatches';
import TeammatchListItem from './TeammatchListItem';
import { useSnackbar } from './SnackbarContext';
import ReservationDialog from './ReservationDialog';
import BookingContainer from '../pages/booking/BookingContainer';
import MyTab from './MyTab';
import { DatePicker, TimePicker } from '@mui/x-date-pickers';


function AddEditSeriesDialog(props) {
    const { open, onClose, getSeries, isEdit, serie, startDateArg, startNameArg, startAssignmentsArg } = props;
    const [days, setDays] = useState({ monday: false, tuesday: false, wednesday: false, thursday: false, friday: false, saturday: false, sunday: false, });
    const [name, setName] = useState("");
    const [startdate, setStartdate] = useState(startDateArg || setSeconds(setMinutes(new Date(), 0), 0));
    const [enddate, setEnddate] = useState(addHours(setSeconds(setMinutes(startdate, 0), 0), 1));
    const [isSubmit, setIsSubmit] = useState(false);
    const [targetCourts, setTargetCourts] = useState([]);
    const [invalidDates, setInvalidDates] = useState(false);
    const [submitDisabled, setSubmitDisabled] = useState(false);
    const [isRepeating, setIsRepeating] = useState(false);
    const [repeatEnddate, setRepeatEnddate] = useState(addHours(addMonths(setSeconds(setMinutes(new Date(), 0), 0), 1), 1));
    const [activeStep, setActiveStep] = useState(0);
    const [step1IsError, setStep1IsError] = useState(false);
    const [step2IsError, setStep2IsError] = useState(false);
    const [step3Info, setStep3info] = useState(false);
    const [settings, setSettings] = useState(null);
    const [assignments, setAssignments] = useState(startAssignmentsArg || null);
    // const [assignmentType, setAssignmentType] = useState("teammatch");
    const [showTeammatchesSelect, setShowTeammatchesSelect] = useState(false);
    const [teammatches, setTeammatches] = useState();
    const [type, setType] = useState("other")
    const daysEnglish = ["monday", "tuesday", "wednesday", "thursday", "friday", "saturday", "sunday"];
    const daysGerman = ["Montag", "Dienstag", "Mittwoch", "Donnerstag", "Freitag", "Samstag", "Sonntag"];
    const [courtType, setCourtType] = useState("outdoor");

    const [openReservationDialog, setOpenReservationDialog] = useState();

    const { showSnackbar } = useSnackbar();

    const udpdateRepeatEnddate = (newDate) => {
        var tmp = setHours(newDate, getHours(enddate));
        setRepeatEnddate(tmp);
    }

    const submitHandlerSeries = (event) => {
        setIsSubmit(true);
        var newEnddate = null;
        var newDays = null;
        if (isRepeating) {
            newEnddate = setHours(repeatEnddate, getHours(enddate));
            newDays = days;
        }
        else {
            newEnddate = enddate;
            newDays = undefined;
        }

        var promise = null;
        if (!isEdit) {
            promise = setSeries_api(newDays, targetCourts, name, startdate, enddate, repeatEnddate, assignments, type, courtType);
            promise.then((result) => { getSeries(); onClose(); showSnackbar("Serie erfolgreich erstellt!", "success"); setIsSubmit(false); })
                .catch((error) => { showSnackbar("Fehlgeschlagen:" + error, "error"); setIsSubmit(false); });
        }
        else {
            promise = updateSeries_api(serie.id, newDays, targetCourts, name, startdate, enddate, repeatEnddate, assignments, type, courtType);
            promise.then((result) => { if (getSeries) { getSeries(); } onClose(); showSnackbar("Serie erfolgreich erstellt!", "success"); setIsSubmit(false); })
                .catch((error) => { showSnackbar("Fehlgeschlagen:" + error, "error"); setIsSubmit(false); });
        }
    }

    const updateDate = (durationMonths) => {
        //setEnddate(addMonths(enddate, durationMonths));
        setRepeatEnddate(addMonths(enddate, durationMonths));
    }

    const getClubSettings = () => {
        var promise = null;
        if (courtType == "indoor") {
            promise = getSettingsIndoor_api();
        }
        else {
            promise = getSettingsOutdoor_api();
        }
        promise.then((result) => setSettings(result.data[0]))
            .catch()
    }

    const removeAssignment = () => {
        setAssignments(() => {
            var tmp;
            tmp = []
            return tmp
        })
    }

    const addAssignment = (teammatch) => {
        //add participanzt to seeds if it is not already assigned
        if (!assignments) {
            setAssignments(assignments => {
                return [teammatch];
            })
        }
        else if (assignments.length >= 0 && assignments.indexOf(teammatch) === -1) {
            setAssignments(assignments => {
                return [...assignments, teammatch];
            })
        }

    }

    const getTeammatches = () => {
        var promise = getTeammatches_api("location=(H)");
        promise.then((result) => setTeammatches(result.data))
            .catch((error) => { });
    }

    useEffect(() => {
        if (open && serie && isEdit) {
            setTargetCourts(JSON.parse(serie.courts))
            if (!serie.days || serie.days == "null") {
                setDays({ monday: false, tuesday: false, wednesday: false, thursday: false, friday: false, saturday: false, sunday: false, });
            }
            else {
                setDays(JSON.parse(serie.days));
            }

            setName(serie.name);
            setType(serie.type);
            setStartdate(new Date(serie.seriesstart));
            setEnddate(new Date(serie.seriesend));
            setRepeatEnddate(new Date(serie.repeatEnd));
            setAssignments(serie.assignmentsData ? serie.assignmentsData : null);
            setActiveStep(0);
            getClubSettings();
            setCourtType(serie.courttype);
            console.log("enddate: ",serie.seriesend)
        }
        if (open && !isEdit) {
            setTargetCourts([]);
            setDays({ monday: false, tuesday: false, wednesday: false, thursday: false, friday: false, saturday: false, sunday: false, });
            setName(startNameArg ? startNameArg : "");
            setType("teammatch");
            setAssignments(startAssignmentsArg ? startAssignmentsArg : null);
            if (startDateArg) {
                var startDateTmp = new Date(startDateArg)
                setStartdate(startDateTmp);
                // setEnddate(addHours(setSeconds(setMinutes(startDateTmp, 0), 0), 1));
                //(settings.booking_duration)
                setEnddate(addMinutes(startDateTmp, settings.booking_duration));
            }
            else {
                var startDateTmp = setMinutes(setSeconds(new Date(), 0), 0)
                setStartdate(setMinutes(setSeconds(new Date(), 0), 0));
            }

            //setEnddate(addHours(addMonths(setSeconds(setMinutes(new Date(), 0), 0), 6), 1));
            setActiveStep(0);
            getClubSettings();
        }
        if (!open) {
            setIsRepeating(false);
            setActiveStep(0);
        }

    }, [open]);

    useEffect(() => {
        if (differenceInCalendarDays(startdate, enddate) > 366 || compareDesc(startdate, enddate) == -1) {
            setInvalidDates(true)
        }
        else {
            setInvalidDates(false)
        }
    }, [enddate, startdate]);

    // TODO: refactor! submitDisbaled should be managed by stepXIsError anmd stepXisError by validation of inputs
    useEffect(() => {
        if (invalidDates || name === "" || step1IsError || step2IsError) {
            setSubmitDisabled(true);
        }
        else {
            setSubmitDisabled(false)
        }
        if (isSubmit) {
            setSubmitDisabled(true)
        }

    }, [invalidDates, name, isSubmit,step1IsError,step2IsError]);

    useEffect(() => {
        if (days && serie && serie.days != "null") {
            setIsRepeating(true);
        }
        else {
            // setIsRepeating(false);
        }
    }, [days]);

    // useEffect(() => {
    //     var dayName = format(startdate, "EEEE").toLowerCase();
    //     var tmp = days;
    //     tmp[dayName] = true;
    //     setDays(tmp);
    //     if(settings){
    //         setEnddate(addMinutes(startdate, settings.booking_duration));
    //     }
    // }, [startdate]);

    useEffect(() => {
        if (isRepeating) {
            //setTargetCourts(newBookingData.court);
            var dayName = format(startdate, "EEEE").toLowerCase();
            var tmp = days;
            tmp[dayName] = true;
            setDays(tmp);
        }
    }, [isRepeating]);

    useEffect(() => {
        setRepeatEnddate(setMinutes(setHours(repeatEnddate, getHours(enddate)), getMinutes(enddate)));
    }, [enddate]);

    useEffect(() => {
        if (settings && !isEdit) {
            setEnddate(addMinutes(startdate, settings.booking_duration));
        }
    }, [settings]);

    useEffect(() => {
        if ((activeStep == 1 || activeStep == 2 || activeStep == 3) && (!name || targetCourts.length == 0|| !targetCourts || (isRepeating && (getDayOfYear(new Date(startdate)) != getDayOfYear(new Date(enddate)))))) {
            setStep1IsError(true)
        }
        else {
            setStep1IsError(false)
        }

    }, [activeStep, isRepeating,targetCourts]);

    useEffect(() => {
        if (isRepeating && Object.values(days).every((key) => (key == false))) {
            setStep2IsError(true);
        }
        else {
            setStep2IsError(false);
        }
    }, [days,isRepeating]);

    useEffect(() => {
        getClubSettings();
    }, [courtType]);

    return (
        <>
            <Dialog open={open} onClose={onClose} elevation={10} fullWidth fullScreen>
                {isEdit ? <DialogTitle sx={{ backgroundColor: "#eee" }}>{"Platzbuchung bearbeiten"}</DialogTitle> : <DialogTitle sx={{ backgroundColor: "#eee" }}><Stack direction={"row"} justifyContent={"space-between"} alignItems={"center"}>{"Platzbuchung erstellen"}<TertiaryButton text="Schließen" endIcon={<Close />} onClick={() => onClose()} /></Stack></DialogTitle>}
                <Divider></Divider>
                <DialogContent>
                    <Grid container flexDirection={"row"} spacing={1}>

                        {/* Stepper */}
                        <Grid item xs={12} md={5}>
                            <Stepper activeStep={activeStep} orientation="vertical">
                                <Step>
                                    <StepLabel error={step1IsError} onClick={() => { setActiveStep(0) }} optional={<Typography variant="body2">verpflichtend</Typography>}>
                                        <Typography variant="body1" fontWeight={500}>Platzbuchung</Typography>
                                    </StepLabel>
                                    <StepContent>
                                        <Grid container spacing={2}>
                                            {isSubmit && <Grid item xs={12} align="center"><CircularProgress></CircularProgress> </Grid>}
                                            <Grid item xs>
                                                <Tabs value={courtType} centered onChange={(event, newValue) => { setCourtType(newValue) }}>
                                                    <MyTab value="outdoor" label="Außenplätze" ></MyTab>
                                                    <MyTab value="indoor" label="Hallenplätze" ></MyTab>
                                                </Tabs>
                                            </Grid>
                                            <Grid item xs={12} md={12}>
                                                <TextField required={true} fullWidth label={"Name der Platzbuchung"} value={name} onChange={(e) => { setName(e.target.value) }}></TextField>
                                            </Grid>
                                            <Grid item xs={12}>
                                                <FormControl fullWidth required>
                                                    <InputLabel id="typeselect">Kategorie</InputLabel>
                                                    <Select required labelId="typeselect" value={type} onChange={(event) => setType(event.target.value)} label="Kategorie">
                                                        <MenuItem value="teammatch">Punktspiel</MenuItem>
                                                        <MenuItem value="training">Training</MenuItem>
                                                        <MenuItem value="other">Sonstige</MenuItem>
                                                    </Select>
                                                </FormControl>
                                            </Grid>
                                            <Hidden mdUp>
                                                <Grid item xs={12}>
                                                    <TertiaryButton text="Reservierung aus Buchungsübersicht wählen" onClick={() => setOpenReservationDialog(true)} endIcon={<Launch />} />
                                                </Grid>
                                            </Hidden>
                                            <Grid item xs={12} md={6}>
                                                <DateTimeField required label={"Startdatum"} date={new Date(startdate)} setDate={setStartdate} format={"dd.MM.yyyy HH:mm"} views={settings && settings.booking_duration == 60 ? ["year", "month", "day", "hours"] : ["year", "month", "day", "hours", "minutes"]} minutesStep={settings && settings.booking_duration} textReadOnly={true}></DateTimeField>
                                            </Grid>
                                            <Grid item xs={12} md={6}>
                                                <TimePicker sx={{width: "100%"}} format={"HH:mm"} required label={"Endzeit"} value={enddate} minTime={new Date(startdate)} onChange={setEnddate} views={settings && settings.booking_duration == 60 ? ["hours"] : [ "hours", "minutes"]} minutesStep={settings && settings.booking_duration} textReadOnly={true}></TimePicker >
                                            </Grid>
                                            {invalidDates &&
                                                <Grid item xs={12}>
                                                    <Alert severity="warning"><Typography>Der Zeitraum muss in in der Zukunft und kleiner als 365 Tage sein.</Typography></Alert>
                                                </Grid>
                                            }
                                            <Grid item xs={12}>
                                                <CourtSelect targetCourts={targetCourts} setTargetCourts={setTargetCourts} courtType={courtType} ></CourtSelect>
                                            </Grid>
                                        </Grid>
                                    </StepContent>
                                </Step>
                                <Step>
                                    <StepLabel error={step2IsError} onClick={() => { setActiveStep(1) }} optional={<Typography variant="body2">optional</Typography>}>
                                        <Typography variant="body1" fontWeight={500}>Abonnement</Typography>
                                    </StepLabel>
                                    <StepContent>
                                        <Grid container spacing={2}>
                                            <Grid item xs={12}>
                                                <Stack direction="row" alignItems="center" spacing={1}>
                                                    <Stack sx={{ flexGrow: 1 }}>
                                                        <Typography variant="body1" fontWeight={500}>Abonnement?</Typography>
                                                        <Typography variant="body2">Soll die Buchung wöchentlich wiederholt werden</Typography>
                                                    </Stack>
                                                    <Switch checked={isRepeating} onChange={(event) => setIsRepeating(event.target.checked)}></Switch>
                                                </Stack>
                                            </Grid>
                                            {isRepeating && getDayOfYear(new Date(startdate)) != getDayOfYear(new Date(enddate)) ?
                                                <Grid item xs={12}>
                                                    <Alert severity='warning'><Typography variant="body2">Bei wiederholenden Buchungen muss das Start- & Enddatum der Platzbuchung (Schritt 1) am selben Tag sein. Zusätzliche Tage können hier in Schritt 2 defieniert werden</Typography></Alert>
                                                </Grid>
                                                : <></>

                                            }
                                            {isRepeating &&
                                                <>
                                                    <Grid item xs={12}>
                                                        <Stack spacing={1}>
                                                            <Stack>
                                                                <Typography variant="body1" fontWeight={500}>Serien-Enddatum</Typography>
                                                                <Typography variant="body2">Bis wann soll die Platzbuchung wiederholt werden?</Typography>
                                                            </Stack>

                                                            <Stack direction="row" spacing={1} justifyContent={"flex-end"}>
                                                                <Chip size="small" label="1 Monat" onClick={() => updateDate(1)} />
                                                                <Chip size="small" label="6 Monat" onClick={() => updateDate(6)} />
                                                                <Chip size="small" label="1 Jahr" onClick={() => updateDate(12)} />
                                                            </Stack>
                                                            <DatePicker size="medium" value={repeatEnddate} minDate={new Date(enddate)} views={["year", "month", "day"]} onChange={udpdateRepeatEnddate} textReadOnly={true} />
                                                        </Stack>
                                                    </Grid>
                                                    {invalidDates &&
                                                        <Grid item xs={12}>
                                                            <Alert severity="warning"><Typography>Der Zeitraum muss in der Zukunft und kleiner als 365 Tage sein.</Typography></Alert>
                                                        </Grid>
                                                    }
                                                    <Grid item xs={12}>
                                                        {days &&
                                                            <Accordion sx={{backgroundColor:"#f5f5f5"}}>
                                                                <AccordionSummary expandIcon={<ExpandMore />}><Typography>Zusätzliche Tage ?</Typography></AccordionSummary>
                                                                <AccordionDetails><WeekDayCheckboxes days={days} setDays={setDays}></WeekDayCheckboxes></AccordionDetails>
                                                            </Accordion>
                                                        }
                                                    </Grid>
                                                </>
                                            }
                                        </Grid>
                                    </StepContent>
                                </Step>

                                <Step>
                                    <StepLabel onClick={() => { setActiveStep(2) }} optional={<Typography variant="body2">optional</Typography>}>
                                        <Typography variant="body1" fontWeight={500}>Verknüpfungen</Typography>
                                    </StepLabel>
                                    <StepContent>
                                        <Grid container spacing={2}>
                                            <Grid item xs={12}>
                                                <Collapse in={step3Info}>
                                                    <Alert severity='info' variant="outlined" onClose={() => setStep3info(false)}>
                                                        <AlertTitle>Info</AlertTitle>
                                                        <Typography variant="body2">Verknüpfte Serienbuchungen erscheinen in der Übersicht der zugehörigen Mannschaft und heben die Warnung der fehlenden Platzbuchung im Punktspielplaner auf. Vordefinerte Verknüpfungen können  aus dem Punktspielplaner heraus initiiert werden. </Typography>
                                                    </Alert>
                                                </Collapse>
                                            </Grid>
                                            <Grid item xs={12} md={6}>
                                                {assignments && assignments.length > 0 ?
                                                    <>
                                                        <Stack direction="row" justifyContent={"space-between"} alignItems="center">
                                                            <Typography variant="body2" color="secondary" fontWeight={500}>{"Verknüpfungen (" + assignments.length + ")"}</Typography>
                                                            {!step3Info &&
                                                                <TertiaryButton startIcon={<InfoSharp color="primary" />} text={"Info"} onClick={() => setStep3info(true)}></TertiaryButton>
                                                            }
                                                        </Stack>

                                                        <List dense sx={{ maxHeight: "300px", overflowY: "auto" }}>
                                                            {assignments.map((assignment, i) => (
                                                                <TeammatchListItem key={i} teammatch={assignment} iconColor="secondary" secondaryAction={<IconButton onClick={() => { setAssignments([...assignments.slice(0, i), ...assignments.slice(i + 1)]) }} ><Delete /></IconButton>} />
                                                            ))}
                                                        </List>
                                                    </>
                                                    :
                                                    <Stack direction="row" justifyContent={"space-between"} alignItems={"center"}>
                                                        <Typography variant="body2" color="secondary" fontWeight={500}>{"Verknüpfungen (0)"}</Typography>
                                                        {!step3Info &&
                                                            <TertiaryButton startIcon={<InfoSharp color="primary" />} text={"Info"} onClick={() => setStep3info(true)}></TertiaryButton>
                                                        }
                                                    </Stack>
                                                }
                                                <Stack direction={"row"} justifyContent={"center"}>
                                                    <TertiaryButton text={showTeammatchesSelect ? "Fertig" : "+ Punktspiele verknüpfen"} onClick={() => { if (teammatches) { setShowTeammatchesSelect(!showTeammatchesSelect) } else { getTeammatches(); setShowTeammatchesSelect(!showTeammatchesSelect) } }} />
                                                </Stack>
                                            </Grid>

                                            <Grid item xs={12} md={6}>
                                                <Collapse in={showTeammatchesSelect}>
                                                    {teammatches ?
                                                        <>
                                                            <Typography variant="body2" color="text.secondary" fontWeight={500}>{"Heim-Punktspiele (" + teammatches.length + ")"}</Typography>
                                                            <List dense sx={{ maxHeight: "300px", overflowY: "auto" }}>
                                                                {/* <ListSubheader disableSticky>{"Punktspiele (" + teammatches.length + ")"}</ListSubheader> */}
                                                                {teammatches.map((teammatch, i) => (
                                                                    !assignments ?
                                                                        <TeammatchListItem key={i} teammatch={teammatch} iconColor="primary" secondaryAction={<IconButton onClick={() => addAssignment(teammatch)}><AddBox color="primary" /></IconButton>} />
                                                                        :
                                                                        assignments.indexOf(teammatch) === -1 ?
                                                                            <TeammatchListItem key={i} teammatch={teammatch} iconColor="primary" secondaryAction={<IconButton onClick={() => addAssignment(teammatch)}><AddBox color="primary" /></IconButton>} />
                                                                            :
                                                                            <></>
                                                                ))}
                                                            </List>
                                                        </>
                                                        :
                                                        <></>
                                                    }
                                                </Collapse>
                                            </Grid>
                                        </Grid>
                                    </StepContent>
                                </Step>

                                <Step>
                                    <StepLabel onClick={() => { setActiveStep(3) }}>
                                        <Typography variant="body1" fontWeight={500}>Zusammenfassung</Typography>
                                    </StepLabel>
                                    <StepContent>

                                        <Alert severity='info' icon={<></>}>
                                            <Stack spacing={1}>
                                                <Stack direction="row" spacing={3}>
                                                    <Info />
                                                    <Typography>{name}</Typography>
                                                </Stack>
                                                <Stack direction="row" spacing={3}>
                                                    <LocationOn></LocationOn>
                                                    <Stack>

                                                        <Stack direction="row" spacing={1} sx={{ maxWidth: "inherit" }}>
                                                            {targetCourts && targetCourts.map((court, i) => (
                                                                i == targetCourts.length - 1 ?
                                                                    <Typography key={i}>{court['name']}</Typography>
                                                                    :
                                                                    <Typography key={i}>{court['name'] + ","}</Typography>
                                                            ))}
                                                        </Stack>
                                                    </Stack>
                                                </Stack>
                                                <Stack direction="row" spacing={3}>
                                                    <Event />
                                                    <Stack>
                                                        {isRepeating ?
                                                            <>
                                                                {Object.keys(days).map((keyName, i) => (
                                                                    days[keyName] &&
                                                                    <Typography key={i}>{isValid(startdate) && isValid(enddate) ? daysGerman[daysEnglish.indexOf(keyName)] + format(startdate, " HH:mm '-'", { locale: de }) + format(enddate, "HH:mm ", { locale: de }) + "Uhr" : ""}</Typography>
                                                                ))}
                                                            </>
                                                            :
                                                            <>
                                                                <Typography>{isValid(startdate) && format(startdate, "EEEE dd. MMMM HH:mm", { locale: de }) + " Uhr"}</Typography>
                                                                <Typography>{isValid(enddate) && format(enddate, "EEEE dd. MMMM HH:mm", { locale: de }) + " Uhr"}</Typography>
                                                            </>
                                                        }
                                                    </Stack>
                                                </Stack>

                                                <Stack direction="row" spacing={3}>
                                                    <EventRepeat />
                                                    <Stack>
                                                        {isRepeating ?
                                                            <>
                                                                <Typography>{isValid(startdate) && isValid(repeatEnddate) && format(startdate, "dd. MMMM yy", { locale: de }) + " - " + format(repeatEnddate, "dd.MMMM yy", { locale: de })}</Typography>
                                                                <Typography variant="body2">Diese Buchung wird wöchtl. wiederholt</Typography>
                                                            </>
                                                            :
                                                            <Typography variant="body2">Diese Buchung wird nicht wiederholt</Typography>
                                                        }

                                                    </Stack>
                                                </Stack>

                                            </Stack>
                                        </Alert>
                                        {isSubmit && <Stack width={"100%"} justifyContent={"center"} alignItems={"center"} spacing={1} sx={{ mt: 2 }}>
                                            <Typography>Serie wird erstellt...</Typography>
                                            <CircularProgress></CircularProgress>
                                        </Stack>}
                                    </StepContent>
                                </Step>
                            </Stepper>
                        </Grid>

                        <Grid item md={7}>
                            <Hidden mdDown>
                                <BookingContainer serieId={serie && serie?.id} setTargetCourts={setTargetCourts} dateProp={startdate} endtime={enddate} targetCourts={targetCourts} adminMode={true} setEnddate={setEnddate} setStartdate={setStartdate}  dialogView isShortText courtTypeProp={courtType}></BookingContainer>
                            </Hidden>
                        </Grid>
                    </Grid>

                </DialogContent>
                <DialogActions sx={{ justifyContent: "space-between" }}>
                    {activeStep != 0 ?
                        <Stack direction="row" spacing={1}>
                            <SecondaryButton text={"Abbrechen"} onClick={onClose} />
                            <SecondaryButton text="Zurück" onClick={() => { setActiveStep(activeStep - 1) }}></SecondaryButton>
                        </Stack>

                        :
                        <SecondaryButton text={"Abbrechen"} onClick={onClose} />
                    }
                    {activeStep < 3 ?
                        <PrimaryButton text="Weiter" onClick={() => { setActiveStep(activeStep + 1) }}></PrimaryButton>
                        :
                        <PrimaryButton text={"Bestätigen"} disabled={submitDisabled} onClick={() => { submitHandlerSeries() }}></PrimaryButton>
                    }
                </DialogActions>
                <ReservationDialog serieId={serie && serie?.id} adminMode={true} setTargetCourts={setTargetCourts} endtime={enddate} targetCourts={targetCourts} setEnddate={setEnddate} setStartdate={setStartdate} open={openReservationDialog} onClose={() => setOpenReservationDialog(false)} dateProp={startdate} originName={"Zurück zur Serienerstellung"} courtTypeProp={courtType}></ReservationDialog>

            </Dialog >
        </>
    )
}

export default AddEditSeriesDialog