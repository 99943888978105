import { ShortText } from '@mui/icons-material';
import { Stack, Typography } from '@mui/material';
import { Box } from '@mui/system';
import React from 'react'

function BookingButton(props) {
    const { data } = props;

    return (
        <>
            {data.spieler1 != null && (data.spieler2 != null || data.guestname != null) ?
                // userbookings
                <Box flexDirection="row" sx={{ backgroundColor: "#ccc", borderRadius: 1, width: "inherit", p: '4px', borderLeft: 4, borderColor: "primary.main" }} justifyContent="center">
                    {props.shortText ?
                        <Typography sx={{ fontSize: { md: '0.9rem', xs: '0.7rem' }, fontWeight: { md: 500, xs: 500 } }} noWrap>{"belegt"}</Typography>
                        :
                        <Stack>
                            <Typography sx={{ fontSize: { md: '0.9rem', xs: '0.7rem' }, fontWeight: { md: 500, xs: 500 } }} noWrap>{data.spieler1_firstname.charAt(0) + ". " + data.spieler1_name}</Typography>
                            <Typography sx={{ fontSize: { md: '0.9rem', xs: '0.7rem' }, fontWeight: { md: 500, xs: 500 } }} noWrap>{data.spieler2_name ? data.spieler2_firstname.charAt(0) + ". " + data.spieler2_name : data.guestname == "Ballmaschine" ? "Ballmaschine" : "Gast"}</Typography>
                        </Stack>
                    }
                </Box>

                : data.spieler1 != null && data.spieler2 == null && data.guestname == null ?
                    // User FreeTextBooking
                    <Box flexDirection="row" sx={{ backgroundColor: "#ccc", borderRadius: 1, width: "inherit", p: '4px', pr: 1, borderLeft: 4, borderColor: "warning.main" }} justifyContent="center">
                        {props.shortText ?
                            <Typography sx={{ fontSize: { md: '0.9rem', xs: '0.7rem' }, fontWeight: { md: 500, xs: 500 } }} noWrap>{"belegt"}</Typography>
                            :
                            <Stack sx={{ minHeight: { xs: "34px", md: "44px" } }} justifyContent={"center"}>
                                <Typography sx={{ fontSize: { md: '0.9rem', xs: '0.7rem' }, fontWeight: { md: 500, xs: 500 } }} noWrap>{data.names}</Typography>
                            </Stack>
                        }
                    </Box>
                    :
                    // Events & Series
                    <Box flexDirection="row" sx={{ backgroundColor: "#ccc", borderRadius: 1, width: "inherit", p: '4px', borderLeft: 4, borderColor: "secondary.main" }} justifyContent="center">
                        {props.shortText ?
                            <Typography sx={{ fontSize: { md: '0.9rem', xs: '0.7rem' }, fontWeight: { md: 500, xs: 500 } }} noWrap>{"belegt"}</Typography>
                            : <Stack>
                                <Typography sx={{ fontSize: { md: '0.9rem', xs: '0.7rem' }, fontWeight: { md: 500, xs: 500 } }} noWrap>{data.names}</Typography>
                                <Typography sx={{ fontSize: { md: '0.9rem', xs: '0.7rem' } }} noWrap color="text.secondary">Serie</Typography>
                            </Stack>
                        }
                    </Box>
            }
        </>
    )
}

export default BookingButton