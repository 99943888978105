import { AccessTime, AcUnit, Category, Delete, Edit, EmojiEvents, Event, EventRepeat, FitnessCenter, Help, Info, LightMode, Link, LocationOn, Timeline } from '@mui/icons-material';
import { Alert, Box, Card, CardContent, Chip, Collapse, Divider, Grid, ListItemIcon, MenuItem, Stack, Tooltip, Typography } from '@mui/material'
import { format } from 'date-fns';
import React, { useState, useEffect } from 'react'
import { deleteSeries } from '../api/Series/series';
import CourtSelect from './CourtSelect';
import WeekDayCheckboxes from './WeekDayCheckboxes';
import { de } from 'date-fns/locale';
import ActionMenu from './ActionMenu';
import DeleteDialog from './DeleteDialog';
import { useNavigate } from 'react-router-dom';
import { BOOKING } from '../navigation/CONSTANTS';

function SeriesActionMenu(props) {
    return (
        <ActionMenu>

            <MenuItem onClick={() => { props.editAction(); }}>
                <ListItemIcon>
                    <Edit fontSize="small" />
                </ListItemIcon>
                Bearbeiten
            </MenuItem>
            <MenuItem onClick={() => { props.bookingLink(); }}>
                <ListItemIcon>
                    <Link fontSize="small" />
                </ListItemIcon>
                Zur Buchungsansicht
            </MenuItem>
            <Divider></Divider>
            <MenuItem onClick={() => { props.deleteAction(); }}>
                <ListItemIcon>
                    <Delete fontSize="small" />
                </ListItemIcon>
                Löschen
            </MenuItem>
        </ActionMenu>
    )
}


function SeriesCard({ adminMode, ...props }) {
    const { serie, getSeries } = props;
    const [state, setState] = useState();
    const [expanded, setExpanded] = useState(false);
    const [courts, setCourts] = useState(undefined);
    const [days, setDays] = useState(undefined);
    const [daysTranslated, setDaysTranslated] = useState();

    // const [openAddDialog, setOpenAddDialog] = useState(false);
    const [openDeleteDialog, setOpenDeleteDialog] = useState(false);
    const [deleteDialogState, setDeleteDialogState] = useState({ title: "Serie löschen", warningText: "Es werden alle dazugehörigen Buchungen gelöscht", openDeleteDialog, deleteObjectName: serie.name, deleteHandler: () => { } });
    const daysEnglish = ["monday", "tuesday", "wednesday", "thursday", "friday", "saturday", "sunday"];
    const daysGerman = ["Montag", "Dienstag", "Mittwoch", "Donnerstag", "Freitag", "Samstag", "Sonntag"];
    const navigate = useNavigate();

    const expandHandler = () => {
        setExpanded(!expanded);
    }
    const openDeleteDialogAction = () => {
        setDeleteDialogState({ ...deleteDialogState, deleteObjectName: serie.name, openDeleteDialog: true, deleteHandler: () => removeSeries() })

    }

    const removeSeries = () => {
        var promise = deleteSeries(serie.id)
        promise.then((result) => { getSeries(); setExpanded(false); })
            .catch();
    }
    //calculate days in german on days change (open)
    useEffect(() => {
        var tmp = [];
        if (days) {
            for (const [key, value] of Object.entries(days)) {
                if (value) {
                    tmp.push(daysGerman[daysEnglish.indexOf(key)]);
                }
            }
            setDaysTranslated(tmp);
        }
    }, [days]);

    useEffect(() => {
        if (serie) {
            try {
                setCourts(JSON.parse(serie.courts));
                setDays(JSON.parse(serie.days));
            } catch (error) {

            }

        }
        return () => {
            setState(null);
        };
    }, [expanded]);

    return (
        <>
            <Card raised sx={{ borderRadius: 1, borderLeft: 4, borderColor: serie.type == "training" ? "primary.main" : serie.type == "teammatch" ? "secondary.main" : serie.type == "other" ? "warning.main" : "grey.main" }} onClick={() => { expandHandler() }}>
                <Stack direction="row" sx={{ background: "#eee", pr: "20px" }} justifyContent={"space-between"} alignItems={"center"}>
                    <Typography align="left" sx={{ typography: { xs: 'body1', md: 'h6' }, fontWeight: { xs: 500, md: 500 }, p: 1 }} noWrap>{serie.name}</Typography>
                    {serie.courttype == "indoor" ?
                        <Tooltip title="Hallenplatz">
                            <AcUnit color="primary"></AcUnit>
                        </Tooltip>

                        :
                        <Tooltip title="Außenplatz">
                            <LightMode color="warning"></LightMode>
                        </Tooltip>

                    }
                </Stack>
                <CardContent
                    sx={{
                        p: 1, "&:last-child": {
                            paddingBottom: 1
                        }
                    }}>
                    <Grid container direction="row" alignItems="center" justifyContent={"flex-start"} spacing={1}>
                        <Grid container item direction="row" justifyContent={"space-between"} alignItems={"center"} xs={12}>
                            <Grid item xs zeroMinWidth>
                                <Stack>
                                    <Stack direction={"row"} spacing={1} alignItems={"center"}>
                                    </Stack>
                                    {serie.days != "null" && serie.days ?
                                        <>
                                            <Typography align="left" sx={{ typography: { xs: 'body2', md: 'body1' }, pl: 1 }} color="text.secondary" noWrap>{format(new Date(serie.seriesstart), "dd.MMM yy HH:mm", { locale: de }) + format(new Date(serie.seriesend), " - HH:mm", { locale: de })}</Typography>
                                            {days != "null" && days ?
                                                <Stack direction={"row"}>
                                                    {/* {Object.keys(days).map((keyName, i) => (
                                                    days[keyName] &&
                                                    <Typography key={i} sx={{ typography: { xs: 'body2', md: 'body1' }, pl: 1 }} color="text.secondary" >{daysGerman[daysEnglish.indexOf(keyName)]}</Typography>
                                                ))} */}
                                                    {daysTranslated && daysTranslated.map((day, i) => (
                                                        <Typography key={i} sx={{ typography: { xs: 'body2', md: 'body1' }, pl: 1 }} color="text.secondary" >{day}</Typography>

                                                    ))

                                                    }
                                                </Stack>
                                                :
                                                <></>
                                            }
                                        </>
                                        :
                                        <>
                                            <Typography align="left" sx={{ typography: { xs: 'body2', md: 'body1' }, pl: 1 }} color="text.secondary" noWrap>{format(new Date(serie.seriesstart), "EEEE dd.MMM yy HH:mm", { locale: de })}</Typography>
                                            <Typography align="left" sx={{ typography: { xs: 'body2', md: 'body1' }, pl: 1 }} color="text.secondary" noWrap>{format(new Date(serie.seriesend), "EEEE dd.MMM yy HH:mm", { locale: de })}</Typography>
                                        </>

                                    }
                                    <Stack direction="row" spacing={1} justifyContent={"center"} sx={{ pl: 1 }}>


                                        {/* <Chip size="small" icon={serie.type == "training" ? <FitnessCenter color="primary"/> : serie.type == "teammatch" ? <EmojiEvents color="primary"/>  :serie.type == "other" ? <Category color="warning.main"/> :<Help/>} /> */}
                                        <Chip size="small" icon={serie.type == "training" ? <FitnessCenter color="primary" /> : serie.type == "teammatch" ? <EmojiEvents color="secondary" /> : serie.type == "other" ? <Category color="warning" /> : <Help color="grey" />}
                                            label={<Typography variant="body2" color={serie.type == "training" ? "primary.main" : serie.type == "teammatch" ? "secondary.main" : serie.type == "other" ? "warning.main" : "grey.main"}>{serie.type == "training" ? "Training" : serie.type == "teammatch" ? "Punktspiel" : serie.type == "other" ? "Sonstige" : "nicht zugewiesen"}</Typography>} />

                                        {serie.days != "null" && serie.days ?
                                            <Chip variant="" sx={{ borderRadius: 2 }} size="small" label={<Stack direction={"row"} spacing={1} justifyContent={"center"} alignItems={"center"}>
                                                <EventRepeat color="grey" fontSize="small"></EventRepeat>
                                                <Typography color="grey" align="center" sx={{ typography: { xs: 'body2', md: "body1" } }}>abo</Typography>
                                            </Stack>
                                            }></Chip>
                                            :
                                            <Chip size="small" label={<Stack direction={"row"} spacing={1} justifyContent={"center"} alignItems={"center"}>
                                                <Event color="grey" fontSize="small"></Event>
                                                <Typography color="grey" align="left" sx={{ typography: { xs: 'body2', md: "body1" } }}>einmalig</Typography>
                                            </Stack>}></Chip>
                                        }
                                    </Stack>
                                </Stack>
                            </Grid>
                            {adminMode &&
                                <Grid item>
                                    <SeriesActionMenu editAction={() => props.openEditDialog(serie)} deleteAction={() => openDeleteDialogAction()} bookingLink={() => navigate(BOOKING + "/" + serie.seriesstart + "?courttype=" + serie.courttype)} />
                                </Grid>
                            }
                        </Grid>
                    </Grid>
                </CardContent>
                <Collapse in={expanded} timeout="auto" unmountOnExit>
                    <Alert severity='info' icon={<></>}>
                        <Stack spacing={1}>
                            <Stack direction="row" spacing={3}>
                                <Info />
                                <Typography>{serie.name}</Typography>
                            </Stack>
                            <Stack direction="row" spacing={3}>
                                <LocationOn></LocationOn>
                                <Stack>

                                    <Stack direction="row" spacing={1} sx={{ maxWidth: "inherit" }}>
                                        {courts && courts.map((court, i) => (
                                            i == courts.length - 1 ?
                                                <Typography key={i}>{court['name']}</Typography>
                                                :
                                                <Typography key={i}>{court['name'] + ","}</Typography>
                                        ))}
                                    </Stack>
                                </Stack>
                            </Stack>
                            <Stack direction="row" spacing={3} alignItems={"center"}>
                                <Event />
                                <Stack>
                                    {days != "null" && days ?
                                        <>
                                            {Object.keys(days).map((keyName, i) => (
                                                days[keyName] &&
                                                <Typography key={i}>{daysGerman[daysEnglish.indexOf(keyName)] + format(new Date(serie.seriesstart), " HH:mm -", { locale: de }) + format(new Date(serie.seriesend), "HH:mm ", { locale: de }) + "Uhr"}</Typography>
                                            ))}
                                        </>
                                        :
                                        <>
                                            <Typography>{serie && format(new Date(serie.seriesstart), "EEEE dd. MMMM HH:mm", { locale: de }) + " Uhr"}</Typography>
                                            <Typography>{serie && format(new Date(serie.seriesend), "EEEE dd. MMMM HH:mm", { locale: de }) + " Uhr"}</Typography>
                                        </>
                                    }
                                </Stack>
                            </Stack>

                            <Stack direction="row" spacing={3}>
                                <EventRepeat />
                                <Stack>
                                    {days != "null" && days ?
                                        <>
                                            <Typography>{format(new Date(serie.seriesstart), "dd. MMMM yy", { locale: de }) + " - " + format(new Date(serie.repeatEnd), "dd.MMMM yy", { locale: de })}</Typography>
                                            <Typography variant="body2">Diese Buchung wird wöchtl. wiederholt</Typography>
                                        </>
                                        :
                                        <Typography variant="body2">Diese Buchung wird nicht wiederholt</Typography>
                                    }

                                </Stack>
                            </Stack>

                        </Stack>
                    </Alert>
                </Collapse>
            </Card>
            <DeleteDialog openDeleteDialog={deleteDialogState.openDeleteDialog} onClose={() => setDeleteDialogState({ ...deleteDialogState, openDeleteDialog: false })} title={deleteDialogState.title} deleteHandler={() => deleteDialogState.deleteHandler()} deleteObjectName={deleteDialogState.deleteObjectName} warningText={deleteDialogState.warningText} />
        </>
    )
}

export default SeriesCard