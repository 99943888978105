import { Table, TableBody, TableCell, TableContainer, TableHead, TableRow, Grid, CircularProgress, ToggleButtonGroup, ToggleButton, Divider, Alert, Stack, Tooltip, IconButton } from '@mui/material';
import { Typography } from "@mui/material";
import React, { useState, useEffect } from 'react';
import DatePickFilter from '../../components/DatePickerFilter';
import BookingDialog from './BookingDialog';
import { addDays, addMinutes, eachDayOfInterval, endOfMonth, endOfWeek, format, getMinutes, isSameDay, isSameHour, isValid, isWithinInterval, startOfMonth, startOfWeek } from 'date-fns';
import { myStyles } from '../../css/Sytles';
import BookingButtonContainer from './BookingButtonContainer';
import PrimaryIconButton from '../../components/PrimaryIconButton';
import { ChevronLeft, ChevronRight, QuestionMark, TextDecreaseSharp, TextIncreaseSharp } from '@mui/icons-material';
import RuleDialog from './RuleDialog';
import { de } from 'date-fns/locale';
import { Box, useMediaQuery, useTheme } from '@mui/system';
import { ADM_COURTS } from '../../navigation/CONSTANTS';
import InternalLink from '../../components/InternalLink';
import { validateUsersBookingRequest_api } from '../../api/Users/users';
import { useSnackbar } from '../../components/SnackbarContext';

function BookingTable(props) {
    //Modal state
    const [modalOpen, setModalOpen] = useState(false);
    const [newBookingData, setNewBookingData] = useState({ time: null, court: null, date: null, mate: null })
    const [openRules, setOpenRules] = useState(false);
    const [shortText, setShortText] = useState(props.isShortText || false);
    const [formattedDates, setFormatedDates] = useState();
    const { showSnackbar } = useSnackbar();
    const theme = useTheme();
    const isSmallScreen = useMediaQuery(theme.breakpoints.down('lg'));

    const classes = myStyles();

    const handleDate = (event, newView) => {
        if (newView == null) {
            return null;
        }
        else {
            props.setDayFilter(newView);
        }
    };

    const validateBookingRequest = (day, id) => {

        var promise = validateUsersBookingRequest_api(day, props.courtType, id);
        promise.then((result) => {
            if (result.data.validation_result == "true") {
                setModalOpen(true);
            }
            else if (props.settings['allow_freetext_bookings']) {
                showSnackbar("Du hast dein persönliches Limit für zukünftige Mitgliederbuchungen bereits erreicht. Freitextbuchungen sind allerdings noch möglich", "warning");
                setModalOpen(true);
            }
            else {
                showSnackbar("Du hast dein persönliches Limit für zukünftige Buchungen bereits erreicht", "warning");
            }
        });


    }

    const handleOpen = (event) => {
        validateBookingRequest(format(new Date(props.dayFilter), "yyyy-MM-dd"), props.userid);
    }

    const handleClose = () => setModalOpen(false);

    // Generate all days in the month
    // Map each date to the desired format
    const calcMonthDates = () => {
        if (isValid(new Date(props.dayFilter))) {
            const monthStart = startOfMonth(props.dayFilter);
            const monthEnd = endOfMonth(props.dayFilter);
            const allDates = eachDayOfInterval({ start: monthStart, end: monthEnd });
            var formattedDates = allDates.map(date => ({
                dayChars: format(date, 'EEEE', { locale: de }).slice(0, 2), // First two chars of day
                dayDigits: format(date, 'dd', { locale: de }), // Day number
                date: date,
            }));
            return (formattedDates);
        }
    }

    const calcWeekDates = () => {
        if (isValid(new Date(props.dayFilter))) {
            const start = startOfWeek(props.dayFilter, { weekStartsOn: 1 });
            const end = endOfWeek(props.dayFilter, { weekStartsOn: 1 });
            const allDates = eachDayOfInterval({ start: start, end: end });
            var formattedDates = allDates.map(date => ({
                dayChars: format(date, 'EEEE', { locale: de }).slice(0, 2), // First two chars of day
                dayDigits: format(date, 'dd', { locale: de }), // Day number
                date: date,
            }));
            return (formattedDates);
        }
    }

    const isHighlighted = (time) => {

        var reservationStart = new Date(props.dayFilter).setHours(time.split(":")[0], time.split(":")[1]);
        var reservationEnd = addMinutes(reservationStart, props.settings?.booking_duration)

        var isSelected = isWithinInterval(new Date(), { start: reservationStart, end: addMinutes(reservationEnd, -1) })
        if (isSelected) {
            return true
        }
        else {
            return false
        }
    }


    useEffect(() => {
        if (isSmallScreen) {
            setFormatedDates(calcWeekDates)
        }
        else {
            setFormatedDates(calcMonthDates)
        }
    }, [props.dayFilter]);
    return (
        <>
            <Grid container direction="row" alignItems="center" rowSpacing={2} columnSpacing={1} mb={1}>
                <Grid item xs={12}>
                    <Grid container item xs={12} justifyContent="space-between" alignItems={"center"}>
                        <Grid item xs>
                            <Stack direction={"row"} spacing={1}>
                                <Tooltip title={"Platzbuchungsregeln"}>
                                    <IconButton onClick={() => { setOpenRules(true); }}><QuestionMark color="primary" /></IconButton>
                                </Tooltip>

                                <Tooltip title={shortText ? "Buchungsnamen erweitern" : "Buchungsnamen abkürzen"}>
                                    <IconButton onClick={() => { setShortText(!shortText); }}>{shortText ? <TextIncreaseSharp color="primary" /> : <TextDecreaseSharp color="primary" />}</IconButton>
                                </Tooltip>
                            </Stack>
                        </Grid>
                        <Grid item>
                            <Typography variant="h4" color="text.secondary">{format(props.dayFilter, "EEEE, dd.MM ", { locale: de })}</Typography>
                        </Grid>
                        <Grid item xs>
                            <Box sx={{ width: "inherit", height: "40px", display: "flex", justifyContent: "flex-end", alignItems: "center" }}>
                                <DatePickFilter hideInput={true} dayFilter={props.dayFilter} setDayFilter={props.setDayFilter} />
                            </Box>
                        </Grid>
                    </Grid>
                    <Grid item sx={{ mt: 1 }} >
                        <Divider variant="fullWidth"></Divider>
                    </Grid>
                    <Grid container direction="row" justifyContent={"space-between"} alignItems={"center"} wrap='nowrap' >

                        <Grid item>
                            <PrimaryIconButton icon={<ChevronLeft />} onClickAction={() => {
                                props.setDayFilter(addDays(props.dayFilter, -1));
                            }} />
                        </Grid>

                        <Grid item sx={{ overflowX: "auto" }}>

                            <ToggleButtonGroup variant="contained" color="primary" value={props.dayFilter} onChange={handleDate} exclusive size="medium">
                                {formattedDates && formattedDates?.map(({ dayChars, dayDigits, date }, i) => (
                                    <ToggleButton value={date} key={i} selected={isSameDay(props.dayFilter, date)} sx={{ textTransform: "none", border: 0, borderRadius: 0, "&.Mui-selected": { borderTop: 2, backgroundColor: "primary.trans" } }}><Stack><Typography variant="body2">{dayChars}</Typography><Typography variant="body2">{dayDigits}</Typography></Stack></ToggleButton>
                                ))
                                }
                            </ToggleButtonGroup>
                        </Grid>
                        {/* </CardContent> */}
                        <Grid item>
                            <PrimaryIconButton icon={<ChevronRight />} onClickAction={() => {
                                props.setDayFilter(addDays(props.dayFilter, 1));
                            }} />

                        </Grid>
                    </Grid>
                </Grid>
                {(!props.settings || props.settings['bookings_enabled'] != 1) && !props.dialogView ?
                    <Grid item xs={12}>
                        <Alert id="alt-booking-deactivated" severity='info'><Typography variant="body1">{"Buchungen für " + (props.courtType == "indoor" ? "Hallenplätze" : "Außenplätze") + " sind zurzeit deaktiviert"}</Typography></Alert>
                    </Grid>
                    : (!props.courts || props.courts.length < 1) ?
                        <Grid item xs={12}>
                            <Alert severity='info'><Typography variant="body1">Es sind keine Tennisplätze vorhanden</Typography><InternalLink linkname="Platzverwaltung" route={ADM_COURTS}></InternalLink></Alert>
                        </Grid>
                        :
                        <Grid item xs={12} align="center">
                            {!props.isLoading ?
                                <TableContainer sx={{ borderRadius: 5, left: 1, zIndex: 2, backgroundColor: "#eee" }}>
                                    <Table size="small" sx={{ borderCollapse: 'separate', borderSpacing: '4px 4px' }}>
                                        <TableHead sx={{ borderRadius: 4 }}>
                                            <TableRow sx={{ borderRadius: 2, borderSpacing: '0px 0px' }}>
                                                <TableCell className={classes.tableHeader} sx={{ position: 'sticky', left: 0, zIndex: 1, border: 0,pl: 1, pr: 1, borderSpacing: 0, width: {xs: "45px", md: "70px"} }}>
                                                </TableCell>
                                                {props.courts.map((court, i) => (
                                                    <TableCell key={i} className={classes.tableHeader} align="center" sx={{ m: 1, border: 0, backgroundColor: "#fff" }}><Typography sx={{ fontSize: { xs: '0.7rem', md: '1rem' }, fontWeight: "bold" }}>{court.name}</Typography></TableCell>
                                                ))
                                                }
                                            </TableRow>
                                        </TableHead>
                                        <TableBody>
                                            {props.rows.map((row, i) => (
                                                <TableRow key={i} sx={{ borderRadius: 1 }}>
                                                    <TableCell key={i} sx={{ position: 'sticky', background: isHighlighted(row[0], props.settings) ? "#D9EFFF" : "#fff", left: 0, zIndex: 2, pl: 1, pr: 1, border: 0, textAlign: "center", width:{xs: "45px", md: "70px"} }}><Typography sx={{ fontSize: { xs: '0.7rem', md: '1rem' }, fontWeight: 400 }}>{row[0]}</Typography></TableCell>
                                                    {props.courts.map((court, i) => (
                                                        // <Tooltip title={court.name + " | " + row[0]} key={i} arrow placement='left' followCursor disableTouchListener>
                                                        <TableCell key={i} align="center" sx={{ border: 0, p: 0, py: 0, pr: shortText ? 0 : 1, backgroundColor: isHighlighted(row[0], props.settings) ? "#D9EFFF" : "#fff", borderRadius: "4px" }}>
                                                            <BookingButtonContainer serieId={props.serieId} setTargetCourts={props.setTargetCourts} endtime={props.endtime} targetCourts={props.targetCourts} adminMode={props.adminMode} startdate={props.startdate} setEnddate={props.setEnddate} setStartdate={props.setStartdate} shortText={shortText} settings={props.settings} courts={props.courts} data={row[court.id]} zeit={row[0]} handleOpen={handleOpen} day={props.dayFilter} setNewBookingData={setNewBookingData} court={court} dialogView={props.dialogView} />
                                                        </TableCell>
                                                        // </Tooltip>
                                                    ))
                                                    }
                                                </TableRow>
                                            ))}
                                        </TableBody>
                                    </Table>
                                </TableContainer>
                                :
                                <CircularProgress></CircularProgress>
                            }
                        </Grid>
                }

            </Grid >
            <BookingDialog open={modalOpen} dayFilter={props.dayFilter} onClose={handleClose} newBookingData={newBookingData} populateTable={props.populateTable} settings={props.settings} courtType={props.courtType}></BookingDialog>
            <RuleDialog open={openRules} onClose={() => setOpenRules(false)} courts={props.courts} settings={props.settings}></RuleDialog>
        </>
    );
}



export default BookingTable
