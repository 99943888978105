
import { Box, Button, Stack, Typography } from '@mui/material'
import { addMinutes, addSeconds, differenceInMinutes, format, isAfter, isEqual, isFuture, isSameDay, isValid, isWithinInterval } from 'date-fns';
import React, { useEffect, useState } from 'react'

import BookingButton from './BookingButton';

function BookingButtonContainer(props) {
  const { data, handleOpen, zeit, day, setNewBookingData, court, settings, setStartdate, endtime, targetCourts, startdate, setTargetCourts, setEnddate, serieId } = props;
  const [bookingDate, setBookingDate] = useState();
  const [selected, setSelected] = useState(false);
  const [locked, setLocked] = useState();




  const addCourtToTargetCourts = () => {
    setTargetCourts((prevCourts) => {
      // Check if the court already exists in the target array
      const exists = prevCourts.some((existingCourt) => existingCourt.id === court.id);
      // If it doesn't exist, add it; otherwise, return the previous state
      if (!exists) {
        return [...prevCourts, { id: court.id, name: court.name }];
      }
      return prevCourts;
    });
  }

  const removeCourtFromTargetCourts = () => {
    setTargetCourts((prevCourts) => {
      const index = prevCourts.findIndex((c) => c.id === court.id);
      if (index === -1) return prevCourts; // If the id is not found, return the unchanged array
      return [
        ...prevCourts.slice(0, index),
        ...prevCourts.slice(index + 1),
      ];
    });
  };


  useEffect(() => {
    if (data.type == "lock") {
      setLocked(true);
    }
    else {
      setLocked(false);
    }
  }, [data]);


  const handleClick = (event) => {
    if (setStartdate) {

      var tmp = new Date(format(day, "yyyy-MM-dd") + "T" + zeit + ":00")
      var tmp_end = addMinutes(tmp, settings?.booking_duration);
      //adjust startdate in case date has been changed after first click
      if (!isSameDay(startdate, tmp_end)) {
        tmp = tmp_end;
        tmp = addMinutes(tmp, -(settings?.booking_duration));
        setStartdate(tmp);
      }
      addCourtToTargetCourts();

      //remove court when startdate is equal
      if (targetCourts.length > 0 && targetCourts.some((existingCourt) => existingCourt.id === court.id) && (isEqual(tmp, startdate))) {
        // anything to do ?
      }
      else if (targetCourts.length > 0 && !isEqual(tmp, startdate) && !isEqual(tmp_end, endtime)) {
        setEnddate(tmp_end);
      }
      else if (targetCourts.length > 0 && !targetCourts.some((existingCourt) => existingCourt.id === court.id) && isEqual(tmp_end, endtime)) {

      }
      else if (targetCourts.length > 0 && targetCourts.some((existingCourt) => existingCourt.id === court.id) && isEqual(tmp_end, endtime)) {
        setEnddate(tmp);
      }
      else {
        setStartdate(tmp)
        setEnddate(tmp_end);
      }
    }
    else {
      setNewBookingData({ time: zeit, court: court.id, name: court.name });
      handleOpen();
    }
  }

  useEffect(() => {
    //booking date
    var datetime = new Date(format(day, "yyyy-MM-dd") + "T" + zeit + ":00");
    setBookingDate(datetime);
  }, [day]);

  useEffect(() => {
    try {
      if (setStartdate) {
        if (isValid(new Date(endtime)) && targetCourts && isValid(new Date(startdate)) && isValid(new Date(bookingDate) & isAfter(endtime, startdate))) {
          if (targetCourts?.some(courtItem => courtItem.id === court.id) && isWithinInterval(addSeconds(bookingDate, 1), { start: startdate, end: endtime })) {
            setSelected(true);
          }
          else {
            setSelected(false);
            // setTargetCourts([]);
          }
        }
        else {
          setSelected(false);
          setTargetCourts([]);
        }
      }
    } catch (error) {
      setTargetCourts([]);
    }

  }, [bookingDate, endtime, setStartdate, startdate, targetCourts]);

  if (locked) {
    return (<>
      <Box flexDirection="row" sx={{ backgroundColor: "#ccc", borderRadius: 1, width: "inherit", p: '4px', borderLeft: 4, borderColor: "error.main" }} justifyContent="center">
        <Stack>
          <Typography sx={{ fontSize: { md: '0.9rem', xs: '0.7rem' }, fontWeight: { md: 500, xs: 500 } }} >{"Gesperrt"}</Typography>
          <Typography sx={{ fontSize: { md: '0.9rem', xs: '0.7rem' } }} >-</Typography>
        </Stack>
      </Box>
    </>
    );
  }
  else if (props.adminMode) {
    return (
      <>
        {!data || data.names === undefined || data.names === "" || data.serieId == serieId 
          ?
          <Stack alignItems={"stretch"} >
            {targetCourts?.some((existingCourt) => existingCourt.id === court.id) && (isEqual(bookingDate, startdate)) &&
              <Button variant="contained" color="warning" onClick={() => removeCourtFromTargetCourts()} sx={{ textTransform: "none", fontSize: { xs: '0.7rem', md: '0.9rem' } }} size="small">
                Platz abwählen
              </Button>
            }
            <Button id={'btn-booking'} color={selected ? "secondary" : "primary"} variant={selected ? "contained" : "outlined"} sx={{ flexGrow: 1, fontSize: { xs: '0.7rem', md: '0.9rem' } }} size="small" onClick={(event) => { handleClick(event) }}>
              <Stack spacing={0}>
                <Typography sx={{ fontSize: { xs: '0.7rem', md: '0.9rem' } }} >Buchen</Typography>
                {settings && settings['button_price_enabled'] ?
                  <Typography variant="body2" sx={{ fontSize: { xs: '0.7rem', md: '0.9rem' }, textTransform: "none" }}>{settings && (settings['member_booking_price'] != 0 || settings['guest_booking_price'] != 0) ? "ab " + Math.min(settings['member_booking_price'], settings['guest_booking_price']) + "€" : ""}</Typography>
                  : <></>
                }
              </Stack>
            </Button>
          </Stack>
          :
          <Stack>
            {/* {<Stack alignItems={"stretch"} >
              {targetCourts?.some((existingCourt) => existingCourt.id === court.id) && (isEqual(bookingDate, startdate)) &&
                <Button variant="contained" color="warning" onClick={() => removeCourtFromTargetCourts()} sx={{ textTransform: "none", fontSize: { xs: '0.7rem', md: '0.9rem' } }} size="small">
                  Platz abwählen
                </Button>
              }
              <Button id={'btn-booking'} color={selected ? "secondary" : "primary"} variant={selected ? "contained" : "outlined"} sx={{ flexGrow: 1, fontSize: { xs: '0.7rem', md: '0.9rem' } }} size="small" onClick={(event) => { handleClick(event) }}>
                <Stack spacing={0}>
                  <Typography sx={{ fontSize: { xs: '0.7rem', md: '0.9rem' } }} >Buchen</Typography>
                  {settings && settings['button_price_enabled'] ?
                    <Typography variant="body2" sx={{ fontSize: { xs: '0.7rem', md: '0.9rem' }, textTransform: "none" }}>{settings && (settings['member_booking_price'] != 0 || settings['guest_booking_price'] != 0) ? "ab " + Math.min(settings['member_booking_price'], settings['guest_booking_price']) + "€" : ""}</Typography>
                    : <></>
                  }
                </Stack>
              </Button>
            </Stack>
            } */}
            <BookingButton id={'btn-booking'} data={data} shortText={props.shortText}></BookingButton>
          </Stack>

        }
      </>
    )
  }
  else {
    return (
      <>
        {!data || data.names === undefined || data.names === ""
          ?
          //  rule avaiable + future  OR no rule + future  => booking button, otherwise nothing 
          (court["time_in_advance"] != null && court["time_in_advance"] != "" && (isFuture(addMinutes(bookingDate, settings.booking_duration)) === true && differenceInMinutes(bookingDate, new Date()) < court["time_in_advance"]))
            || (!(court["time_in_advance"] != null && court["time_in_advance"] != "") && isFuture(addMinutes(bookingDate, settings.booking_duration)))
            ?
            <Button id={'btn-booking'} color="primary" sx={{ fontSize: { xs: '0.7rem', md: '0.9rem' } }} size="small" onClick={() => { handleClick() }} variant="outlined">
              <Stack spacing={0}>
                <Typography sx={{ fontSize: { xs: '0.7rem', md: '0.9rem' } }} >Buchen</Typography>
                {settings && settings['button_price_enabled'] ?
                  <Typography variant="body2" sx={{ fontSize: { xs: '0.7rem', md: '0.9rem' }, textTransform: "none" }}>{settings && (settings['member_booking_price'] != 0 || settings['guest_booking_price'] != 0) ? "ab " + Math.min(settings['member_booking_price'], settings['guest_booking_price']) + "€" : ""}</Typography>
                  : <></>
                }
              </Stack>
            </Button>
            :
            <></>
          :
          <BookingButton id={'btn-booking'} data={data} shortText={props.shortText}></BookingButton>
        }
      </>
    )
  }
}

export default BookingButtonContainer