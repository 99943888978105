import { Person } from '@mui/icons-material';
import { Alert, Dialog, DialogActions, DialogContent, DialogTitle, Divider, Grid, MenuItem, Select, Stack, TextField, Typography } from '@mui/material';
import React, { useEffect, useState } from 'react'
import { setWinner_api } from '../../api/Events/events';
import PrimaryButton from '../../components/PrimaryButton';
import TertiaryButton from '../../components/TertiaryButton';
import { useSnackbar } from '../../components/SnackbarContext';
function GamesSelect({ name, onChange, ...props }) {
    return (
        <Select size="small" name={name} onChange={onChange} IconComponent={null}>
            <MenuItem value={7}>7</MenuItem>
            <MenuItem value={6}>6</MenuItem>
            <MenuItem value={5}>5</MenuItem>
            <MenuItem value={4}>4</MenuItem>
            <MenuItem value={3}>3</MenuItem>
            <MenuItem value={2}>2</MenuItem>
            <MenuItem value={1}>1</MenuItem>
            <MenuItem value={1}>0</MenuItem>
            <MenuItem value={undefined}>-</MenuItem>
        </Select>
    )
}

function MatchDialog({ selectedMatch, open, getMatches, grandSlamMode, onClose, ...props }) {

    const [result, setResult] = useState({});
    const [winner, setWinner] = useState();
    const [error, setError] = useState(true);
    const { showSnackbar } = useSnackbar();



    const submitAction = () => {
        var promise = null;
        promise = setWinner_api(selectedMatch.tournament_id, selectedMatch.id, winner, result);
        promise.then((result) => { getMatches(); onClose(); showSnackbar("Ergebnis erfolgreich eingetragen", "success"); })
            .catch((error) => { showSnackbar("Fehler beim Eintragen des Ergebnisses", "error") });
    }

    const handleChangeSet = (event) => {
        setResult({
            ...result,
            [event.target.name]: event.target.value,
        });
    };
    const changeWinner = () => {
        if (winner == selectedMatch.player1) {
            setWinner(selectedMatch.player2)
        }
        else {
            setWinner(selectedMatch.player1)
        }
    }

    const determineWinnner = () => {
        setWinner();
        //determine winner of set
        //if grandslam mode winner is first to 3 else winner is first to 2
        var sets_p1 = 0;
        var sets_p2 = 0;
        for (var i = 1; i < 6; i++) {
            var key_p1 = "s" + i + "p1"
            var key_p2 = "s" + i + "p2"

            if (result[key_p1] && result[key_p2]) {
                if (result[key_p1] > result[key_p2]) {
                    sets_p1 += 1;
                }
                else {
                    sets_p2 += 1;
                }
            }
        }

        if ((!grandSlamMode && sets_p1 >= 2) || (grandSlamMode && sets_p1 >= 3)) {
            setWinner(selectedMatch.player1);
            return 0;
        }

        if ((!grandSlamMode && sets_p2 >= 2) || (grandSlamMode && sets_p2 >= 3)) {
            setWinner(selectedMatch.player2);
            return 0;
        }

    }

    const validateResult = () => {
        setError(false);
        for (var key in result) {
            if (!result.hasOwnProperty(key)) continue;
            //validate range -> 0-7
            //validate set result
            //  -> compare values with key same char at pos 1 and diffrent char at pos 3
            //  -> one of both needs to have  6 && the other on needs to have < 6-1, one of both can have 7 the other needs to have 6

            if (result[key] >= 0 && result[key] <= 7) {
                //setError(false);
                for (var key2 in result) {
                    if (!result.hasOwnProperty(key2)) continue;
                    //find result of other player in this set
                    console.log("key: " + key + " || key2: " + key2)
                    if (key2.charAt(1) === key.charAt(1) && key2.charAt(3) !== key.charAt(3)) {

                        if ((result[key] == 7 && result[key2] == 6) || (result[key] == 6 && result[key2] == 7) || (result[key] == 7 && result[key2] == 5) || (result[key] == 5 && result[key2] == 7) || (result[key] == 6 && result[key2] < 5) || (result[key] < 5 && result[key2] == 6)) {
                            break;
                        }
                        else {
                            setError(true);
                            return 0;
                        }
                    }
                    else {
                    }
                }
            }
            else {
                if (result[key] != undefined) {
                    setError(true);
                }
                return 0;
            }
        }
    }

    useEffect(() => {
        setResult({});
        setWinner();
        setError(false);
        return () => {
        };
    }, [open]);

    useEffect(() => {
        determineWinnner();
        validateResult();
        return () => {
        };
    }, [result]);


    return (
        <Dialog open={open} onClose={onClose} fullWidth maxWidth={"sm"}>
            <DialogTitle sx={{ backgroundColor: "#eee" }}>
                Ergebins melden
            </DialogTitle>
            <Grid item xs={12} >
                <Alert severity="info" ><Typography variant="body2">Trage das Ergebins ein - der Gewinner wird automatisch ermittelt</Typography></Alert>
            </Grid>
            <DialogContent sx={{ p: 1, pt: 2 }}>
                <Grid container spacing={2} >
                    <Grid item xs={12} container spacing={1} sx={{ ml: 1, mr: 1 }}>
                        <Grid item container direction={{ xs: "column", sm: "row" }} justifyContent={"space-between"} alignItems={"center"} rowSpacing={1}>
                            <Grid item alignSelf={{ xs: "flex-start", sm: "center" }}>
                                <Stack direction="row" spacing={2} >
                                    <Person color="primary" />
                                    <Typography variant="body1" fontWeight={500}>{selectedMatch && selectedMatch.player1_name}</Typography>
                                </Stack>
                            </Grid>
                            <Grid item alignSelf={"center"} >
                                <Stack direction="row" spacing={1}>
                                    <GamesSelect name={"s1p1"} onChange={handleChangeSet} />
                                    <GamesSelect name={"s2p1"} onChange={handleChangeSet} />
                                    <GamesSelect name={"s3p1"} onChange={handleChangeSet} />   
                                    {grandSlamMode ?
                                        <>
                                            <GamesSelect name={"s4p1"} onChange={handleChangeSet} />
                                            <GamesSelect name={"s5p1"} onChange={handleChangeSet} />
                                        </>
                                        :
                                        <></>
                                    }
                                </Stack>
                            </Grid>
                        </Grid>
                        <Grid item xs={12}>
                            <Divider></Divider>
                        </Grid>

                        <Grid item container direction={{ xs: "column-reverse", sm: "row" }} justifyContent={"space-between"} alignItems={"center"} rowSpacing={1}>
                            <Grid item alignSelf={{ xs: "flex-start", sm: "center" }}>
                                <Stack direction="row" spacing={2}>
                                    <Person color="primary" />
                                    <Typography fontWeight={500}>{selectedMatch && selectedMatch.player2_name}</Typography>
                                </Stack>
                            </Grid>
                            <Grid item alignSelf={"center"}>
                                <Stack direction="row" spacing={1}>
                                    <GamesSelect name={"s1p2"} onChange={handleChangeSet} />
                                    <GamesSelect name={"s2p2"} onChange={handleChangeSet} />
                                    <GamesSelect name={"s3p2"} onChange={handleChangeSet} />
                                    {grandSlamMode ?
                                        <>
                                            <GamesSelect name={"s4p2"} onChange={handleChangeSet} />
                                            <GamesSelect name={"s5p2"} onChange={handleChangeSet} />
                                        </>
                                        :
                                        <></>
                                    }
                                </Stack>
                            </Grid>
                        </Grid>
                    </Grid>
                    {winner && error == false ?
                        <Grid item xs={12}>
                            <Alert severity="info">
                                <Stack direction="column" alignItems="center" justifyContent={"space-between"} sx={{ width: "100%" }}>
                                    <Typography variant="body2" fontWeight={500}>Sieger: {winner == selectedMatch.player1 ? selectedMatch.player1_name : selectedMatch.player2_name}</Typography>
                                    <TertiaryButton text="Klicke hier um den Sieger zu ändern" onClick={() => changeWinner()}></TertiaryButton>
                                </Stack>
                            </Alert>
                        </Grid>
                        :
                        <></>
                    }
                    {error &&
                        <Grid item xs={12}>
                            <Alert severity="warning"><Typography>Das Ergebins enthält Fehler</Typography></Alert>
                        </Grid>
                    }
                    <Grid item xs={12}>
                        <Divider></Divider>
                    </Grid>
                </Grid>
            </DialogContent>
            <DialogActions sx={{ justifyContent: "space-between" }}>
                <TertiaryButton text="Abbrechen" onClick={() => { onClose(); }}></TertiaryButton>
                <PrimaryButton text={"Bestätigen"} disabled={error} onClick={() => { submitAction() }}></PrimaryButton>
            </DialogActions>
        </Dialog >
    )
}

export default MatchDialog