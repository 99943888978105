import { Alert, Dialog, DialogActions, DialogContent, DialogTitle, Divider, Grid, Stack, Switch, Typography, TextField, Collapse, AlertTitle, ListItem, List, FormControl, InputLabel, Select, MenuItem } from '@mui/material';
import React, { useState, useEffect } from 'react';
import { getUsersNames } from '../../api/Users/users';
import { addMinutes, format, getHours, getMinutes, isValid, parse, setHours, setMinutes, setSeconds } from 'date-fns';
import CustomAutocomplete from '../../components/CustomAutocomplete';
import { AccessTime, Assignment, EuroSymbolSharp, Event, LocationOn } from '@mui/icons-material';
import { de } from 'date-fns/locale';
import { Box } from '@mui/system';
import { setReservation } from '../../api/Reservations/reservations';
import SecondaryButton from '../../components/SecondaryButton';
import PrimaryButton from '../../components/PrimaryButton';
import TimeField from '../../components/TimeField';
import DatePickFilter from '../../components/DatePickerFilter';
import { setSeries_api } from '../../api/Series/series';
import { getCourts_api } from '../../api/Courts/courts';
import { useSnackbar } from '../../components/SnackbarContext';


function AdminBookingDialog(props) {
  const { newBookingData, populateTable, settings,courtType } = props;

  const [users, setUsers] = useState([{}]);
  const [isFreeText, setIsFreeText] = useState(false);
  const [isGuest, setIsGuest] = useState(false);
  const [isBulk, setIsBulk] = useState(false);
  const [guestName, setGuestName] = useState("");
  const [name, setName] = useState("");
  const [endtime, setEndTime] = useState(setHours(setSeconds(setMinutes(new Date(), 0), 0), parseInt(newBookingData.time) + 2));
  const [isSeries, setIsSeries] = useState(false);
  const [startdate, setStartdate] = useState(new Date());
  const [enddate, setEnddate] = useState(new Date());
  const [targetCourts, setTargetCourts] = useState();
  const [days, setDays] = useState({ monday: false, tuesday: false, wednesday: false, thursday: false, friday: false, saturday: false, sunday: false });
  const [isMember, setIsMember] = useState(false);
  const [repeatEnddate, setRepeatEnddate] = useState(new Date());
  const [submitDisabled, setSubmitDisabled] = useState(false);
  const [parsedDate, setParseDate] = useState(new Date());
  const [expansionMinutes, setExpansionMinutes] = useState(null);
  const [expansionIndex, setExpansionIndex] = useState(0);
  const [freetext, setFreetext] = useState("");
  const [isUserFreetext, setIsUserFreetext] = useState(false);
  const [seriesType, setSeriesType] = useState("other");
  const { showSnackbar } = useSnackbar();

  const [mate, setMate] = useState("");
  const [player, setPlayer] = useState("");


  const handleChange = (event) => {
    switch (event.target.name) {
      case "isFreeText":
        setIsFreeText(event.target.checked);
        break;
      case "freeText":
        setName(event.target.value);
        break;
      case "isGuest":
        setIsGuest(event.target.checked);
        setIsUserFreetext(false);
        break;
      case "isGuestText":
        setGuestName(event.target.value);
        break;
      case "isBulk":
        setIsBulk(event.target.checked);
        break;
      case "isSeries":
        setIsSeries(event.target.checked);
        break;
      case "isMember":
        setIsMember(event.target.checked);
        break;
      case "isUserFreetext":
        setIsUserFreetext(event.target.checked);
        setIsGuest(false);
        break;
      default:
        break;
    }

  }

  const handlebook = () => {
    var promise = null;
    var data = null;
    if (isFreeText && !isMember) {
      if (!name || name === "") {
        showSnackbar("Bitte geben Sie einen Namen an", "warning");

        return;
      }

      if (isBulk && !isSeries) {
        data = { court: newBookingData.court, time: newBookingData.time + ":00", day: format(new Date(props.dayFilter.toString()), "yyyy-MM-dd"), name: name, endtime: format(endtime, "HH:mm:ss") };
        promise = setReservation(data);
        promise.then(() => { populateTable(); showSnackbar("Reservierung erfolgreich", "success"); props.onClose() })
          .catch(error => { showSnackbar("Error: " + error, "error"); });
      }
      else if (isSeries) {
        promise = setSeries_api(days, targetCourts, name, startdate, enddate, repeatEnddate,null,seriesType,courtType);
        promise.then(() => { populateTable(); showSnackbar("Serie erfolgreich erstellt", "success"); props.onClose() })
          .catch(error => { showSnackbar("Error: " + error, "error"); });
      }
      else {
        data = { court: newBookingData.court, time: newBookingData.time + ":00", day: format(new Date(props.dayFilter.toString()), "yyyy-MM-dd"), name: name };
        promise = setReservation(data);
        promise.then(() => { populateTable(); showSnackbar("Reservierung erfolgreich", "success"); props.onClose() })
          .catch(error => { showSnackbar("Error: " + error, "error"); });
      }
    }
    else if (isGuest) {
      const data = { court: newBookingData.court, time: newBookingData.time + ":00", day: format(new Date(props.dayFilter.toString()), "yyyy-MM-dd"), player: player, guestname: guestName.trim(), endtime: enddate, starttime: parsedDate };
      promise = setReservation(data);
      promise.then(() => { populateTable(); showSnackbar("Reservierung erfolgreich", "success"); props.onClose() })
        .catch(error => { showSnackbar("Error: " + error, "error"); });
    }
    else if (isUserFreetext) {
      const data = { court: newBookingData.court, time: newBookingData.time + ":00", day: format(new Date(props.dayFilter.toString()), "yyyy-MM-dd"), player: player, name: freetext.trim(), endtime: enddate, starttime: parsedDate, isFreetext: isUserFreetext };
      promise = setReservation(data);
      promise.then(() => { populateTable(); showSnackbar("Reservierung erfolgreich", "success"); props.onClose() })
        .catch(error => { showSnackbar("Error: " + error, "error"); });

    }
    else {
      if (!mate && !player) {
        showSnackbar("Bitte wählen Sie einen Partner aus", "warning");

        return;
      }
      const data = { court: newBookingData.court, time: newBookingData.time + ":00", day: format(new Date(props.dayFilter.toString()), "yyyy-MM-dd"), mate_id: mate, player: player, endtime: enddate, starttime: parsedDate };

      promise = setReservation(data);
      promise.then(() => { populateTable(); showSnackbar("Reservierung erfolgreich", "success"); props.onClose() })
        .catch(error => { showSnackbar("Error: " + error, "error"); });

    }
  }

  const fetchUsers = () => {
    const userPromise = getUsersNames();
    userPromise.then((result) => {

      var users_autocomplete = [];

      for (var i = 0; i < result.data.length; i++) {
        var user_id = result.data[i].id;
        var user_label = result.data[i].firstname + " " + result.data[i].surname + " | " + user_id;
        users_autocomplete.push({ label: user_label, id: user_id });
        //users_autocomplete.push(result.data[i].firstname + " " + result.data[i].surname)
      }
      setUsers(users_autocomplete)
    });
  }

  const getCourt = () => {
    var promise = getCourts_api("?id=" + newBookingData.court)
    promise.then((result) => setTargetCourts([{ "id": result.data[0].id, "name": result.data[0].name }]))
      .catch();
  }

  const calculateEndBookingTime = (minutes) => {
    var tempdate = addMinutes(parsedDate, minutes);
    setEnddate(tempdate);
  }

  useEffect(() => {
    fetchUsers();
    setGuestName("");
    setName("");
    setIsGuest(false);
    setMate("");
    setPlayer("");
    setIsFreeText(false);
    setIsBulk(false);
    setIsSeries(false);
    setIsUserFreetext(false);
    setFreetext("");
    setIsMember(false);

    if (props.open) {
      //parse new booking date object and assiign hours and minutes initially to end and repeat date
      var bookingDate = parse(format(props.dayFilter, "yyyy-MM-dd") + " " + newBookingData.time, "yyyy-MM-dd HH:mm", new Date())
      setParseDate(bookingDate);
      setStartdate(bookingDate);
      var bookingEndDate = addMinutes(bookingDate, settings.booking_duration);
      setEnddate(bookingEndDate)
      setRepeatEnddate(bookingEndDate);
      getCourt();
      var dayName = format(new Date(props.dayFilter.toString()), "EEEE").toLowerCase();;
      var tmp = days;
      tmp[dayName] = true;
      setDays(tmp);

    }
  }, [props.open]);

  useEffect(() => {
    setRepeatEnddate(setMinutes(setHours(startdate, getHours(endtime)), getMinutes(endtime)));
    setEnddate(setMinutes(setHours(startdate, getHours(endtime)), getMinutes(endtime)));
  }, [endtime]);

  useEffect(() => {
    if (settings) {
      var bookingDate = parse(format(props.dayFilter, "yyyy-MM-dd") + " " + newBookingData.time, "yyyy-MM-dd HH:mm", new Date())
      if (isBulk) {
        setEndTime(addMinutes(bookingDate, (2 * settings.booking_duration)));
      }
      else {
        setEndTime(addMinutes(bookingDate, (settings.booking_duration)));
      }
    }
  }, [isBulk]);

  useEffect(() => {
    if (!isValid(startdate) || !isValid(enddate) || !isValid(repeatEnddate)) {
      setSubmitDisabled(true);
    }
    else {
      setSubmitDisabled(false);
    }
    return () => {

    };
  }, [startdate, enddate, repeatEnddate, endtime]);

  useEffect(() => {
    if (settings) {
      var tempExpansionMinutes = [];
      if (settings.allowed_bookings_per_day > 1) {
        for (var i = 1; i <= settings.allowed_bookings_per_day; i++) {
          tempExpansionMinutes[i - 1] = i * settings.booking_duration;
        }
        setExpansionMinutes(tempExpansionMinutes);
      }
    }
  }, [settings]);


  return (
    <>
      <Dialog open={props.open} onClose={props.onClose} elevation={10} fullWidth maxWidth={"sm"}>
        <DialogTitle sx={{ backgroundColor: "#eee" }}>Platzbuchung</DialogTitle>

        <Grid container direction="row" spacing={2}>
          <Grid item xs={12}>
            <Alert severity="info"><AlertTitle>Buchungsinformationen</AlertTitle>
              <Stack spacing={1}>
                <Stack direction="row" spacing={2} alignItems={"center"}>
                  <Event color="primary" sx={{ width: "20px", height: "20px" }} />
                  <Typography variant="body1" sx={{ fontWeight: 400 }}>{format(new Date(props.dayFilter.toString()), "EEEE dd. MMMM ", { locale: de })}</Typography>
                </Stack>
                <Stack direction="row" spacing={2} alignItems={"center"}>
                  <AccessTime color="primary" sx={{ width: "20px", height: "20px" }} />
                  <Stack>
                    <Stack direction="row">
                      < Typography variant="body1" sx={{ fontWeight: 400 }}>{isValid(parsedDate) && format(parsedDate, 'HH:mm')}</Typography>
                      < Typography variant="body1" sx={{ fontWeight: 400 }}>{isValid(enddate) && " - " + format(enddate, "HH:mm")}</Typography>
                    </Stack>
                    {isSeries &&
                      < Typography variant="body1" sx={{ fontWeight: 400 }}>{isValid(repeatEnddate) && "wöchtl. Wiederholung bis " + format(repeatEnddate, 'EEEE dd. MMMM', { locale: de })}</Typography>
                    }
                  </Stack>
                </Stack>
                <Stack direction="row" spacing={2} alignItems={"center"}>
                  <LocationOn color="primary" sx={{ width: "20px", height: "20px" }} />
                  <Typography variant="body1" sx={{ fontWeight: 400 }}>{newBookingData.name}</Typography>
                </Stack>
                {/* <Typography variant="body1" sx={{ fontWeight: 500 }}>{+newBookingData.time + " Uhr auf " + newBookingData.name}</Typography> */}
                {settings && (settings.member_booking_price != 0 || isGuest && settings.guest_booking_price != 0) ?
                  <>
                    <Divider></Divider>
                    <Stack direction="row" spacing={2} alignItems={"center"}>
                      <EuroSymbolSharp color="primary" sx={{ width: "20px", height: "20px" }} />
                      <Stack>
                        {isGuest ?
                          <Typography variant="body1">{settings && settings.guest_booking_price != 0 && expansionMinutes != null ? "Diese Buchung kostet " + (settings.guest_booking_price * (expansionMinutes[expansionIndex] / settings.booking_duration)).toFixed(2) + "€" : "kostenlos"}</Typography>
                          : isUserFreetext || isFreeText ?
                            <Typography variant="body1">{"kostenlos"}</Typography>
                            :
                            <Typography variant="body1">{settings && settings.member_booking_price != 0 && expansionMinutes != null ? "Diese Buchung kostet " + (settings.member_booking_price * (expansionMinutes[expansionIndex] / settings.booking_duration)).toFixed(2) + "€" : "kostenlos"}</Typography>
                        }
                        <Typography variant="body2">{"Die Kosten werden dem Buchenden in Rechnung gestellt"}</Typography>

                      </Stack>
                    </Stack>
                  </>
                  : <></>
                }
              </Stack>
            </Alert>

          </Grid>
        </Grid>
        <DialogContent sx={{ mt: 2 }}>
          <Grid container direction="row" spacing={2}>
            {!isMember &&
              <Grid item xs={12}>
                <Stack direction="row" justifyContent={"space-between"} spacing={1} alignItems={"center"}>
                  <Stack sx={{ flexGrow: 1 }}>
                    <Typography variant="subtitle1" gutterBottom sx={{ fontWeight: 'bold' }}>Freitextbuchung</Typography>
                    <Typography variant="body2">Möchtest du einen individuellen Namen verwenden (ohne Mitglieder)?</Typography>
                  </Stack>
                  <Switch size="medium" name="isFreeText" id="isFreeText" checked={isFreeText} onChange={handleChange}></Switch>
                </Stack>
              </Grid>
            }
            {isFreeText &&
              <>
                <Grid item xs={12}>
                  <TextField required name="freeText" fullWidth label={"Name der Buchung"} value={name} onChange={handleChange}></TextField>
                </Grid>
                <Grid item xs={12}><Divider></Divider></Grid>
                <Grid container item xs={12} alignItems="center" justifyContent={"space-between"}>
                  <Box>
                    <Typography variant="subtitle1" gutterBottom sx={{ fontWeight: 'bold' }}>Massenbuchung</Typography>
                    <Typography variant="body2">Sollen mehrere Stunden gebucht werden ?</Typography>
                  </Box>
                  <Switch size="medium" name="isBulk" id="isBulk" checked={isBulk} onChange={handleChange}></Switch>
                </Grid>
                {isBulk &&
                  <Grid item xs={12}>
                    <TimeField name="endtime" fullWidth label={"Massenbuchung bis"} date={endtime} setDate={setEndTime} onChange={handleChange} views={settings.booking_duration == 60 ? ['hours'] : ['hours', 'minutes']} minutesStep={settings.booking_duration} textReadOnly></TimeField>
                  </Grid>
                }
                <Grid item xs={12}>
                  <Divider></Divider>
                </Grid>
                <Grid item xs={12}>
                  <Stack direction="row" spacing={1} alignItems={"center"} justifyContent={"center"}>
                    <Stack sx={{ flexGrow: 1 }}>
                      <Typography variant="subtitle1" gutterBottom sx={{ fontWeight: 'bold' }}>Abonnement</Typography>
                      <Typography variant="body2">Soll die Buchung wöchenltich wiederholt werden?</Typography>
                    </Stack>
                    <Switch size="medium" name="isSeries" id="isSeries" checked={isSeries} onChange={handleChange}></Switch>
                  </Stack>
                </Grid>

                {isSeries &&
                  <>
                    <Grid item xs={12}>
                      <DatePickFilter hideInput={true} size="medium" dayFilter={repeatEnddate} setDayFilter={setRepeatEnddate}/>
                    </Grid>
                    <Grid item xs={12}>
                      <FormControl fullWidth required>
                        <InputLabel id="typeselect">Kategorie</InputLabel>
                        <Select required labelId="typeselect" value={seriesType} onChange={(event) => setSeriesType(event.target.value)} label="Kategorie">
                          <MenuItem value="teammatch">Punktspiel</MenuItem>
                          <MenuItem value="training">Training</MenuItem>
                          <MenuItem value="other">Sonstige</MenuItem>
                        </Select>
                      </FormControl>
                    </Grid>
                  </>
                }
              </>
            }
            {!isFreeText &&
              <>
                <Grid item xs={12}>
                  <Stack direction="row" justifyContent={"space-between"} spacing={1} alignItems={"center"}>
                    <Stack sx={{ flexGrow: 1 }}>
                      <Typography variant="subtitle1" gutterBottom sx={{ fontWeight: 'bold' }}>Mitgliederbuchung</Typography>
                      <Typography variant="body2">Möchtest du eine Buchung für Mitglieder durchführen? (mit mind. einem Mitglied)</Typography>
                    </Stack>
                    <Switch size="medium" name="isMember" id="isMember" checked={isMember} onChange={handleChange}></Switch>
                  </Stack>
                </Grid>

                {isMember &&
                  <>
                    <Grid item xs={12}>
                      <Divider></Divider>
                    </Grid>
                    {settings.allow_guest_bookings ?
                      <Grid container item xs={12} alignItems="center" justifyContent={"space-between"}>
                        <Box>
                          <Typography variant="subtitle1" gutterBottom sx={{ fontWeight: 'bold' }}>Gastbuchung?</Typography>
                          <Typography variant="body2">Buchung mit einem Gast oder der Ballmaschine?</Typography>
                        </Box>
                        <Switch size="medium" name="isGuest" id="isGuest" checked={isGuest} onChange={handleChange}></Switch>
                      </Grid>
                      :
                      <></>
                    }
                    {props.settings && props.settings['allow_freetext_bookings'] ?
                      <Grid item xs={12}>
                        <Stack direction="row" alignItems={"center"} justifyContent={"space-between"}>
                          <Stack>
                            <Typography variant="subtitle1" gutterBottom sx={{ fontWeight: 'bold' }}>Freitext</Typography>
                            <Typography variant="body2">Eine Buchung außerhalb des verf. Buchungskontingents unter Angabe des Zwecks</Typography>
                          </Stack>
                          <Switch size="medium" name="isUserFreetext" id="isUserFreetext" checked={isUserFreetext} onChange={handleChange}></Switch>
                        </Stack>
                      </Grid>
                      :
                      <></>
                    }
                    <Grid item xs={12}>
                      <CustomAutocomplete options={users} label="Vereinsmitglied 1" setTarget={setPlayer} />
                    </Grid>
                    {isGuest ?
                      <Grid item xs={12}>
                        <TextField name="isGuestText" fullWidth label={"Name des Gasts / Ballmaschine"} value={guestName} onChange={handleChange}></TextField>
                      </Grid>
                      : isUserFreetext ?
                        <Grid item xs={12}>
                          <TextField required name="isUserFreetext" fullWidth label={"Buchungszweck"} value={freetext} onChange={(event) => setFreetext(event.target.value)}></TextField>
                        </Grid>
                        :
                        <Grid item xs={12}>
                          <CustomAutocomplete options={users} label="Vereinsmitglied 2" setTarget={setMate} />
                        </Grid>
                    }
                    {settings && settings.allowed_bookings_per_day > 1 ?
                      <>
                        <Grid item xs={12}>
                          <Stack direction="row" justifyContent={"space-between"} alignItems={"center"}>
                            <Box>
                              <Typography variant="subtitle1" gutterBottom sx={{ fontWeight: 'bold' }}>Buchung verlängern?</Typography>
                              <Typography variant="body2">{"Möchtest du mehr als " + settings.booking_duration + " Minuten reservieren?"}</Typography>
                            </Box>
                            <Switch size="medium" name="isBulk" id="isBulk" checked={isBulk} onChange={handleChange}></Switch>
                          </Stack>

                          <Collapse in={isBulk}>
                            <Grid item xs={12}>
                              {!isUserFreetext ?
                                <List dense>
                                  {expansionMinutes && expansionMinutes.map((minutes, i) => (
                                    <ListItem key={i} sx={{ px: 0 }}>
                                      <SecondaryButton variant={expansionIndex === i ? "contained" : "outlined"} text={expansionMinutes[i] + " Minuten"} onClick={() => { calculateEndBookingTime(minutes); setExpansionIndex(i) }} fullWidth></SecondaryButton>
                                    </ListItem>
                                  ))
                                  }
                                </List>
                                :
                                <TimeField name="endtime" fullWidth label={"Buchung bis"} date={endtime} setDate={setEndTime} views={settings.booking_duration == 60 ? ['hours'] : ['hours', 'minutes']} minutesStep={settings.booking_duration} textReadOnly sx={{ mt: 2 }}></TimeField>
                              }

                            </Grid>
                          </Collapse>
                        </Grid>
                      </>
                      :
                      <></>
                    }
                  </>

                }
              </>
            }
          </Grid>
        </DialogContent>
        <Divider></Divider>
        <DialogActions sx={{ justifyContent: "space-between" }}>
          <SecondaryButton text={"Abbrechen"} onClick={props.onClose} />
          <PrimaryButton text="Buchen" onClick={handlebook} disabled={submitDisabled} />
        </DialogActions>
      </Dialog >
    </>
  );
}

export default AdminBookingDialog;
