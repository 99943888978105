import { Download, Event } from '@mui/icons-material';
import { Alert, Container, Grid, Table, TableHead, TablePagination, TableRow, ToggleButton, ToggleButtonGroup, Typography } from '@mui/material';
import { format } from 'date-fns';
import React, { useState, useEffect } from 'react'
import { useLocation, useNavigate, useSearchParams } from 'react-router-dom';
import { exportGuestReservations_api, getGuestReservations_api, getReservationsByDay_api, getReservations_api } from '../../../api/Reservations/reservations';
import { getUsersNames } from '../../../api/Users/users';
import BookingCard from '../../../components/BookingCardv2';
import CustomAutocomplete from '../../../components/CustomAutocomplete';
import Footer from '../../../components/Footer'
import Header from '../../../components/Header/Header'
import TitleCard from '../../../components/TitleCard';
import { getSettings_api } from '../../../api/Settings/settings';
import PrimaryButton from '../../../components/PrimaryButton';
import { exportDataToCSV } from '../../../utils/export';
import MainContentContainer from '../../../components/MainContentContainer';

function AdmReservationsContainer() {

    const [searchParam, setSearchParam] = useSearchParams();
    const [view, setView] = useState(searchParam.get("view") || "all");
    const [users, setUsers] = useState([{}]);
    const [targetUser, setTargetUser] = useState(searchParam.get("id") || "");
    const [year, setYear] = useState(searchParam.get("year") || "");
    const [bookings, setBookings] = useState([]);
    const [settings, setSettings] = useState(null);
    const [initialInputValue, setInitialInputValue] = useState("");

    const navigate = useNavigate();


    function useQuery() {
        const { search } = useLocation();

        return React.useMemo(() => new URLSearchParams(search), [search]);
    }

    const handleView = (event, newView) => {
        setView(newView);
    };

    const getReservations = () => {
        var promise = null;

        if (view === "paid") {
            promise = getGuestReservations_api(targetUser, year);
            promise.then((result) => { setBookings(result.data) })
                .catch((error) => { });
        }
        else if (view === "all") {
            promise = getReservations_api(targetUser);
            promise.then((result) => { setBookings(result.data) })
                .catch((error) => { });
        }
        else if (view === "today") {
            promise = getReservationsByDay_api(format(new Date(), "yyyy-MM-dd"), targetUser)
            promise.then((result) => { setBookings(result.data) })
                .catch((error) => { });
        }
        else { }
    }

    //pagintaion
    const [page, setPage] = useState(0);
    const [rowsPerPage, setRowsPerPage] = useState(10);

    const handleChangePage = (event, newPage) => {
        setPage(newPage);
    };

    const handleChangeRowsPerPage = (event) => {
        setRowsPerPage(parseInt(event.target.value, 10));
        setPage(0);
    };

    const fetchUsers = () => {
        const userPromise = getUsersNames();
        userPromise.then((result) => {

            var users_autocomplete = [];

            for (var i = 0; i < result.data.length; i++) {
                var user_id = result.data[i].id;
                var user_label = result.data[i].firstname + " " + result.data[i].surname + " | " + user_id;
                users_autocomplete.push({ label: user_label, id: user_id });
                //users_autocomplete.push(result.data[i].firstname + " " + result.data[i].surname)
            }
            setUsers(users_autocomplete);
            setInitialInputValue(users_autocomplete.filter(function (element) { return element.id == searchParam.get("id") })[0]);

        });
    }

    const getClubsettings = () => {
        var promise = getSettings_api();
        promise.then((result) => { setSettings(result.data[0]) })
            .catch()
    }

    const exportPaidReservation = () => {
        var promise = exportGuestReservations_api(targetUser, year)
        promise.then((result) => exportDataToCSV(result.data, "Buchungen_" + targetUser))
            .catch();
    }

    //populate users autocomplete
    useEffect(() => {
        fetchUsers();
        getClubsettings();
        return () => {
        };
    }, []);

    //update reservations based on view
    useEffect(() => {
        getReservations(view, targetUser, year);
        setSearchParam({ view: view, id: targetUser || "", year: year || "" });
        return () => {
        };
    }, [view, targetUser, year]);


    return (
        <>
            <Header></Header>
            <Grid container direction="row" rowSpacing={3} columnSpacing={2}>
                <Grid item xs={12}>
                    <TitleCard id="crd-title-reservations" title={"Reservierungen"} subheader={"Verwalte alle Reservierungen!"} icon={<Event sx={{ width: 40, height: 40 }} />}></TitleCard>
                </Grid>
            </Grid>
            <MainContentContainer>
                <Grid container direction="row" justifyContent={"center"} alignItems={"center"} rowSpacing={2} columnSpacing={2}>
                    <Grid item xs={12}>
                        <Grid container direction="row" columnSpacing={2} justifyContent="space-between">
                            <Grid item>
                                <ToggleButtonGroup variant="contained" color="primary" value={view} onChange={handleView} exclusive size="small">
                                    <ToggleButton value={"all"} sx={{ textTransform: "none" }}>Alle</ToggleButton>
                                    <ToggleButton value={"paid"} sx={{ textTransform: "none" }}>Kostenpfl.</ToggleButton>
                                    <ToggleButton value={"today"} sx={{ textTransform: "none" }}>Heute</ToggleButton>
                                </ToggleButtonGroup>
                            </Grid>
                            <Grid item xs={6}>
                                <CustomAutocomplete size="small" options={users} initialInputValue={initialInputValue} label="Mitglied" setTarget={setTargetUser}></CustomAutocomplete>
                            </Grid>
                        </Grid>
                    </Grid>

                    <Grid item container xs={12} direction="row" alignItems="center" justifyContent={"space-between"}>
                        <Grid item>
                            <Table>
                                <TableHead>
                                    <TableRow>
                                        <TablePagination
                                            rowsPerPageOptions={[10, 50, 100]}
                                            count={bookings.length || 0}
                                            rowsPerPage={rowsPerPage}
                                            labelRowsPerPage={"Anzahl"}
                                            page={page}
                                            onPageChange={handleChangePage}
                                            onRowsPerPageChange={handleChangeRowsPerPage}
                                            sx={{ border: 0 }}
                                        />
                                    </TableRow>
                                </TableHead>
                            </Table>
                        </Grid>
                        {view == "paid" ?
                            <Grid item>
                                <PrimaryButton startIcon={<Download />} text="Export" onClick={() => exportPaidReservation()}></PrimaryButton>
                            </Grid>
                            :
                            <></>
                        }

                    </Grid>
                    <Grid container item xs={12} spacing={2}>
                        {bookings && bookings.length > 0 ?
                            bookings.slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage).map((booking, i) => (
                                <Grid item key={i} xs={12} md={6}>
                                    <BookingCard booking={booking} adminMode={true} getBookings={getReservations} clubSettings={settings} />
                                </Grid>
                            ))
                            :
                            <Grid item xs={12}>
                                <Alert severity="info" variant="filled"><Typography>Keine Buchungen vorhanden</Typography></Alert>
                            </Grid>
                        }
                    </Grid>
                </Grid>
            </MainContentContainer>
            <Footer></Footer>
        </>
    )
}

export default AdmReservationsContainer