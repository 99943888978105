import React, { useEffect } from 'react'
import { Container, Typography } from '@mui/material'

function TermsContainer() {

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);
  return (
    <>
      <Container maxWidth="lg" px={{ px: 1 }}>


        <Typography variant="h6"><p ><span ><strong>Nutzungsbedingungen</strong></span></p></Typography>
        <Typography variant="body1">
          <p><strong>Allgemeines:</strong></p>
          <p>F&uuml;r den Zugriff auf und die Nutzung unserer Website sowie der hierauf befindlichen Informationen gelten ausschlie&szlig;lich die folgenden Nutzungsbedingungen, soweit nicht ausdr&uuml;cklich in diesen Nutzungsbedingungen oder an entsprechender Stelle auf unserer Website etwas anderes geregelt ist.</p>
          <p>Soweit im Folgenden vom MCP die Rede ist, ist MyClubPortal gemeint.</p>
          <p>Gruns&auml;tzlich ist die Nutzung des System nur f&uuml;r vollwertige Mitglieder gestattet.</p>
          <p><strong>Urheberrecht:</strong></p>
          <p>Die Inhalte dieser Website sind urheberrechtlich gesch&uuml;tzt. S&auml;mtliche Nutzungen und/oder Verwertungen sind ohne vorherige schriftliche Zustimmung vom MCP untersagt, soweit nicht in diesen Nutzungsbedingungen oder an entsprechender Stelle auf unserer Website etwas anderes geregelt ist.</p>
          <p>Alle innerhalb des Internetangebots genannten und gegebenenfalls durch Dritte gesch&uuml;tzten Marken- und Kennzeichen unterliegen uneingeschr&auml;nkt den Bestimmungen des jeweils g&uuml;ltigen Kennzeichenrechts und den Besitzrechten der jeweiligen eingetragenen Eigent&uuml;mer. Allein aufgrund der blo&szlig;en Nennung ist nicht der Schluss zu ziehen, dass Markenzeichen nicht durch Rechte Dritter gesch&uuml;tzt sind.</p>
          <p><strong>Haftungsausschluss:</strong></p>
          <p>MCP &uuml;bernimmt keinerlei Garantien, weder ausdr&uuml;cklich noch impliziert, f&uuml;r die Richtigkeit, Vollst&auml;ndigkeit oder Aktualit&auml;t der auf dieser Website zur Verf&uuml;gung gestellten Informationen. Dies gilt auch f&uuml;r Inhalte und Angaben von Dritten.</p>
          <p>MCP haftet nicht f&uuml;r Sch&auml;den, die direkt oder indirekt durch die Nutzung oder Nichtnutzung der angebotenen und zur Verf&uuml;gung gestellten Informationen entstehen. Dies gilt auch f&uuml;r Inhalte und Angaben von Dritten. Eine Haftung f&uuml;r Folgesch&auml;den sowie entgangenen Gewinn ist ausgeschlossen.</p>
          <p>Dar&uuml;ber hinaus befinden sich auf dieser Website Hyperlinks zu Websites anderer Anbieter. MCP hat keinerlei Einfluss auf die aktuelle und zuk&uuml;nftige Gestaltung solcher verlinkter Seiten. Auch der Link selbst stellt keine inhaltliche Zustimmung oder Billigung solcher Seiten von Dritten dar. MCP &uuml;bernimmt deshalb keine Verantwortung f&uuml;r die Inhalte, die Verf&uuml;gbarkeit, die Richtigkeit oder die Genauigkeit der verlinkten Seiten, deren Angebote, Links oder Werbeanzeigen.</p>
          <p><strong>Geistiges Eigentum:</strong></p>
          <p>Alle Rechte an Inhalten, Marken und geistigem Eigentum auf der Plattform bleiben im Besitz der Plattform und ihrer Lizenzgeber.</p>
          <p><strong>Datenschutz:</strong></p>
          <p>Die Datenschutzbestimmungen regeln die Sammlung, Verwendung und Weitergabe von pers&ouml;nlichen Informationen. Die Benutzer erkl&auml;ren sich mit diesen Bestimmungen einverstanden. Die Datenschutzbestimmungen k&ouml;nnen im Detail im anschlie&szlig;enden Abschnitt eingesehen werden.</p>
          <p><strong>Nutzungsbeschr&auml;nkungen:</strong></p>
          <p>Die Benutzer verpflichten sich, die Plattform nicht f&uuml;r rechtswidrige oder unangemessene Zwecke zu nutzen und keine Inhalte zu ver&ouml;ffentlichen, die gegen geltende Gesetze versto&szlig;en.</p>
          <p><strong>&Auml;nderungen der Nutzungsbedingungen:</strong></p>
          <p>Die Plattform kann diese Nutzungsbedingungen nach eigenem Ermessen &auml;ndern. Benutzer werden &uuml;ber &Auml;nderungen informiert, und die fortgesetzte Nutzung gilt als Zustimmung zu den aktualisierten Bedingungen.</p>
          <p><strong>Rechtswirksamkeit:</strong></p>
          <p>Dieser Haftungsausschluss ist als Teil des Internetangebotes zu betrachten, von dem aus auf diese Seite verwiesen wurde. Sofern Teile oder einzelne Formulierungen dieses Textes der geltenden Rechtslage nicht, nicht mehr oder nicht vollst&auml;ndig entsprechen sollten, bleiben die &uuml;brigen Teile der Nutzungsbedingungen in ihrem Inhalt und ihrer G&uuml;ltigkeit hiervon unber&uuml;hrt.</p>
          <p><strong>Anwendbares Recht:</strong></p>
          <p>Diese Nutzungsbedingungen unterliegen den Gesetzen des Landes, in dem die Plattform ihren Sitz hat.</p>
        </Typography>
        <Typography variant="h6"><p ><span ><strong>Allgemeine Datenschutz-Verordnung (DSGVO)</strong></span></p></Typography>
        <Typography variant="body1">
          <p><strong>Rechtsgundlage:</strong></p>
          <p>Insofern wir f&uuml;r die Verarbeitung der personenbezogenen Daten eine&nbsp;Einwilligung&nbsp;der betroffenen Person eingeholt haben, gilt Artikel 6 Absatz 1 Unterabsatz 1a der DSGVO als rechtliche Grundlage.</p>
          <p><strong>Angabe des Datenverabeitungszwecks:</strong></p>
          <p><a>Um die Nutzung so benutzerfreundlich wie m&ouml;glich zu gestalten und s&auml;mtliche verf&uuml;gbaren Funktionen anbieten zu k&ouml;nnen, werden Systemnutzungsdaten (Vorname, Nachname, E-Mail, Password) aufgenommen, verarbeitet und gespeichert.</a></p>
          <p><strong>Datenspeicherungsdauer:</strong></p>
          <p>Alle personenbezogenen Daten, werden zur Laufzeit der SItzung durch den Einsatz von Sitzungs-Cookies gespeichert. Die Informationen einer Sitzung werden automatisch gel&ouml;scht, sobald die Sitzungszeit abgelaufen ist. Die Sitzungszeit betr&auml;gt aktuell eine Stunde. Grunds&auml;tzliche Systemnutzungsdaten (Vor- &amp; Nachname, E-Mail) werden persistent auf einer Datenbank abgelegt.&nbsp;</p>
          <p><strong>Hinweis auf Betroffenenrechte:</strong></p>
          <p>Im Sinne der DSGVO z&auml;hlen Sie als Betroffener, wenn personenbezogene Daten, die Sie betreffen, von uns verarbeitet werden. Aus diesem Grund k&ouml;nnen Sie von verschiedenen Betroffenenrechten Gebrauch machen, die in der Datenschutz-Grundverordnung verankert sind. Hierbei handelt es sich um das&nbsp;Auskunftsrecht&nbsp;(Artikel 15 DSGVO), das&nbsp;Recht auf Berichtigung&nbsp;(Artikel 16 DSGVO), das&nbsp;Recht auf L&ouml;schung&nbsp;(Artikel 17 DSGVO), das&nbsp;Recht auf Einschr&auml;nkung der Verarbeitung&nbsp;(Artikel 18 DSGVO), das&nbsp;Widerspruchsrecht&nbsp;(Artikel 21 DSGVO), das&nbsp;Recht auf Beschwerde bei einer Aufsichtsbeh&ouml;rde&nbsp;(Artikel 77 DSGVO) sowie das&nbsp;Recht auf Daten&uuml;bertragbarkeit&nbsp;(Artikel 20 DSGVO).</p>
        </Typography>
      </Container>
    </>
  )
}

export default TermsContainer