
import React from 'react';
import { InputAdornment, TextField } from '@mui/material'
import SearchIcon from '@mui/icons-material/Search';

export default function TableFilter({filter,setFilter,...props}) {

  return (
    <>
      <TextField size="small" fullWidth variant="outlined" placeholder='Suche' value={filter} onChange={(event) => setFilter(event.target.value)}
        InputProps={{
          endAdornment: (
            <InputAdornment position="end">
              <SearchIcon />
            </InputAdornment>),
        }}>
      </TextField>
    </>
  )
}
